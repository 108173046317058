<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    :items="items"
    multiple
    outlined
    dense
    hide-details
    placeholder="Type or select"
    :menu-props="{
      offsetY: true,
      maxWidth: '100%'
    }"
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="data">
      <v-chip
        v-if="!limited || data.index === 0"
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        small
        label
        @click="data.select"
        @click:close="remove(data.item)"
      >
        {{ data.item[$attrs['item-text']] }}
      </v-chip>
      <span v-if="limited && data.index === 1">(+{{ value.length - 1 }} others)</span>
    </template>
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.code"></v-list-item-title>
          <v-list-item-subtitle v-html="data.item.desc" class="zone-description"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: ['value', 'items', 'limited'],
  methods: {
    remove(item) {
      this.$emit('input', this.value.filter((v) => v !== item[this.$attrs['item-value']]))
    }
  }
}
</script>
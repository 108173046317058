<template>
  <v-form>
    <div>
      <h4 class="mb-2">Images</h4>
      <div class="d-flex flex-wrap gap-xl">
        <div v-for="img in local.images" :key="img.id" class="relative image-preview">
          <v-img
            :src="img.url"
            width="60"
            max-width="60"
            height="60"
          />
          <v-btn fab x-small elevation="1" class="image-close" @click="removeImage(img.id)">
            <v-icon>$mdi-close</v-icon>
          </v-btn>
        </div>
        <label for="image-input" class="image-input">
          <v-icon>$mdi-plus</v-icon>
        </label>
        <v-file-input id="image-input" class="d-none" multiple @change="handleImageInputChange($event)"></v-file-input>
      </div>
    </div>

    <div class="mt-4">
      <h4>Documents</h4>
      <div v-for="pdf in local.pdfs" :key="pdf.id" class="d-flex align-center gap-md">
        <v-icon>{{ fileIcon(pdf.type) }}</v-icon>
        <div class="flex-grow-1">
          <div style="font-size: 18px;">{{ pdf.label }}</div>
        </div>
        <v-btn icon @click="onPdfRemove(pdf.id)">
          <v-icon>$mdi-trash-can</v-icon>
        </v-btn>
      </div>
      <v-form
        ref="pdfForm"
        lazy-validation
        class="d-flex align-start mt-2"
      >
        <v-row dense>
          <v-col cols="12" sm="6  ">
            <v-file-input
              v-model="pdfFile"
              label="Upload Doc, PDF, CSV files"
              outlined
              dense
              hide-details
              accept="application/pdf, application/msword, text/csv, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              prepend-icon="$mdi-file"
              :rules="[$rules.required]"
            ></v-file-input>
          </v-col>
          <v-col cols="12" sm="6  ">
            <v-text-field
              v-model="pdfLabel"
              label="Label"
              outlined
              dense
              hide-details
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-btn icon class="ml-2 mb-1" @click="onPdfAdd">
          <v-icon>$mdi-plus</v-icon>
        </v-btn>
      </v-form>
    </div>
  </v-form>
</template>

<script>
export default {
  props: ['value', 'isEdit', 'uploadedImages', 'uploadedFiles'],
  data() {
    return {
      pdfFile: null,
      pdfLabel: null
    }
  },
  computed: {
    local() {
      return this.value
    }
  },
  methods: {
    handleImageInputChange(value) {
      const max = this.local.images.length ? Math.max(...this.local.images.map((item) => item.id)) : 0
      const newImages = value.map((img, i) => ({
        id: max + i + 1,
        url: URL.createObjectURL(img),
        file: img
      }))

      this.$emit('input', { ...this.local, images: [...this.local.images, ...newImages] })
    },
    removeImage(id) {
      this.$emit('input', { ...this.local, images: this.local.images.filter((img) => img.id !== id) })
    },
    onPdfAdd() {
      if (this.$refs.pdfForm.validate()) {
        const max = this.local.pdfs.length ? Math.max(...this.local.pdfs.map((item) => item.id)) : 0
        this.$emit('input', {
          ...this.local,
          pdfs: [
            ...this.local.pdfs,
            {
              id: max + 1,
              file: this.pdfFile,
              type: this.pdfFile.type,
              label: this.pdfLabel
            }
          ]
        })

        this.pdfFile = null
        this.pdfLabel = null
        this.$refs.pdfForm.reset()
      }
    },
    onPdfRemove(id) {
      this.$emit('input', {
        ...this.local,
        pdfs: this.local.pdfs.filter((pdf) => pdf.id !== id)
      })
    },
    fileIcon(type) {
      switch(type) {
        case 'application/msword':
          return '$mdi-file-word'
        case 'application/pdf':
          return '$mdi-file-pdf-box'
        default:
          return '$mdi-file'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: dashed 1px rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.image-preview {
  border: solid 1px rgba(0, 0, 0, 0.54);
}
.image-close {
  position: absolute;
  top: -16px;
  right: -16px;
}
</style>
module.exports = {
  filter: {
    soldDt: [
      {
        title: 'All',
        value: 'all'
      }, {
        title: 'Last 3 Months',
        value: 'last_3'
      }, {
        title: 'Last 6 Months',
        value: 'last_6'
      }, {
        title: 'Last 12 Months',
        value: 'last_12'
      }
    ],
    salePrice: [
      {
        title: 'All',
        value: 'all'
      }, {
        title: 'Under $5 million',
        value: 'under_5'
      }, {
        title: '$5 million to $10 million',
        value: '5_to_10'
      }, {
        title: '$10 million to $20 million',
        value: '10_to_20'
      }
    ]
  }
}
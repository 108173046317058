<template>
  <div>
    <AreaProfileMenu
      :selected-profile="selectedProfile"
      :profiles="profiles"
      :sales-type="salesType"
      @input="handleProfileSelect($event)"
    />

    <v-row dense>
      <v-col cols="6">
        <div class="mb-2">
          <label
            :for="`${address_detail_pid}land_area`"
            class="caption"
            :class="{ 'required': isLandAreaRequired }"
          >
            {{ isMultiLabel ? 'Combined Land Area (Multiple Properties)' : 'Land Area' }}
            <span v-if="isLandAreaRequired">*</span>
          </label>
          <CurrencyText
            :id="`${address_detail_pid}land_area`"
            v-model="local.land_area"
            dense
            outlined
            hide-details
            suffix="SQM"
            :rules="isLandAreaRequired ? [$rules.required] : []"
            @input="update('land_area', $event)"
          />
        </div>
      </v-col>
      <v-col cols="6">
        <div class="mb-2">
          <label
            :for="`${address_detail_pid}building_area`"
            class="caption"
            :class="{ 'required': isBuildingAreaRequired }"
          >
            {{ isMultiLabel ? 'Combined Building Area (Multiple Properties)' : 'Building Area' }}
            <span v-if="isBuildingAreaRequired">*</span>
          </label>
          <div class="d-flex align-center">
            <CurrencyText
              :id="`${address_detail_pid}building_area`"
              v-model="local.building_area"
              dense
              outlined
              hide-details
              suffix="SQM"
              :rules="isBuildingAreaRequired ? [$rules.required] : []"
              @input="update('building_area', $event)"
            />
            <BtnDropdown
              v-model="local.building_area_type"
              :items="['NLA', 'GBA', 'GLA', 'GLAR']"
              :right="true"
              height="40"
              @input="update('building_area_type', $event)"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <div>
      <a @click="showMoreAreas = !showMoreAreas" class="userselect-none">
        {{ showMoreAreas ? '- Show less' : '+ Add Area Breakdown' }}
      </a>
      <v-expand-transition>
        <div v-show="showMoreAreas">
          <v-row dense>
            <template v-for="i in 4">
              <v-col cols="6" :key="`type-${i}`">
                <label :for="`area_${i}_type_id`" class="caption">Space {{ i }}</label>
                <DropdownSelect
                  :id="`area_${i}_type_id`"
                  v-model="local[`area_${i}_type_id`]"
                  ky="buildingSpaceTypes"
                  outlined
                  dense
                  hide-details
                  @input="update(`area_${i}_type_id`, $event)"
                />
              </v-col>
              <v-col cols="6" :key="`area-${i}`">
                <label :for="`area_${i}`" class="caption">Space {{ i }} Area</label>
                <v-text-field
                  v-if="areaType(local[`area_${i}_type_id`]) === 1"  
                  :id="`area_${i}`"
                  type="number"
                  hide-spin-buttons
                  suffix="Spaces"
                  outlined
                  clearable
                  hide-details
                  clear-icon="$mdi-close"
                  v-model="local[`area_${i}`]"
                  @input="update(`area_${i}`, $event)"
                />
                <CurrencyText
                  v-else
                  :id="`area_${i}`"
                  suffix="SQM"
                  v-model="local[`area_${i}`]"
                  @input="update(`area_${i}`, $event)"
                />
              </v-col>
            </template>
          </v-row>
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import AreaProfileMenu from '@/components/AreaProfileMenu'

export default {
  components: {
    AreaProfileMenu
  },
  props: {
    value: {
      type: Object
    },
    salesType: {
      type: String,
      default: 'salesComp'
    },
    address_detail_pid: {
      type: String
    },
    is_strata_unit: {
      type: Boolean,
      default: false
    },
    isLeaseProfileFilled: {
      type: Boolean,
      default: false
    },
    isMultiLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMoreAreas: false,

      profiles: [],
      selectedProfile: null
    }
  },
  computed: {
    ...mapGetters('dropdown', ['dropdownItem']),
    local() {
      return this.value
    },
    isLandAreaRequired() {
      // If address_detail_pid, it means its for lease
      if (this.address_detail_pid && !this.isLeaseProfileFilled) {
        return false
      }

      if (this.address_detail_pid) {
        if (
          this.isLeaseProfileFilled ||
          this.salesType === 'leaseComp'
        ) {
          return false
        }
      } else {
        if (this.salesType === 'salesComp') {
          return !this.is_strata_unit
        }
      }

      return false
    },
    isBuildingAreaRequired() {
      // If address_detail_pid, it means its for lease
      if (this.address_detail_pid && !this.isLeaseProfileFilled) {
        return false
      }

      if (
        this.isLeaseProfileFilled ||
        this.address_detail_pid ||
        this.salesType === 'leaseComp'
      ) {
        return true
      }

      if (this.salesType === 'salesComp') {
        return this.is_strata_unit
      }

      return false
    }
  },
  mounted() {
    this.getProfiles()
  },
  methods: {
    ...mapActions('property', ['getCompsFromAddressDetailPid']),
    areaType(type_id) {
      const areaType = this.dropdownItem('buildingSpaceTypes', type_id)

      return areaType?.type
    },
    async getProfiles() {
      // Get area profiles for address
      if (this.address_detail_pid) {
        this.getCompsFromAddressDetailPid({
          address_detail_pid: this.address_detail_pid,
          salesType: this.salesType
        }).then((res) => {
          this.profiles = res.data.items
        })
      }
    },
    update(key, value) {
      this.$emit('input', { ...this.local, [key]: value })
    },
    handleProfileSelect(item) {
      this.selectedProfile = item
      this.$emit('input', {
        'building_area': item.building_area,
        'building_area_type': item.building_area_type,
        'land_area': item.land_area,
        'area_1_type_id': item.area_1_type_id,
        'area_1': item.area_1,
        'area_2_type_id': item.area_2_type_id,
        'area_2': item.area_2,
        'area_3_type_id': item.area_3_type_id,
        'area_3': item.area_3,
        'area_4_type_id': item.area_4_type_id,
        'area_4': item.area_4
      })
    }
  }
}
</script>
import api from '@/api'

export default {
  namespaced: true,

  state: {
    dropdowns: {}
  },
  mutations: {
    SET_DROPDOWNS(state, items) {
      state.dropdowns = items
    }
  },
  actions: {
    getDropdowns({ commit }) {
      api.get('/app/dropdowns').then((res) => {
        commit('SET_DROPDOWNS', res.data.items)
      })
    }
  },
  getters: {
    dropdownItem: (state) => (k, id) => {
      return state.dropdowns[k] ? state.dropdowns[k].find((v) => v.id == id) : null
    },
    dropdownItemName: (state) => (id, key) => {
      let item = null

      {
        item = state.dropdowns[key].find((v) => v == id)

        if (!item) {
          item = state.dropdowns[key].find((v) => v.id == id)
        }
      }

      return item?.name
    }
  }
}
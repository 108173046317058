const routes = [
  {
    path: '/admin/companies',
    name: 'admin.companies',
    component: () => import(/* webpackChunkName: "admin.companies" */ '@/pages/admin/CompaniesList.vue')
  }, {
    path: '/admin/users',
    name: 'admin.users',
    component: () => import(/* webpackChunkName: "admin.users" */ '@/pages/admin/UsersManagement.vue')
  }, {
    path: '/admin/users/edit/:id',
    name: 'admin.users.edit',
    component: () => import(/* webpackChunkName: "admin.users.edit" */ '@/pages/admin/EditUser.vue')
  }, {
    path: '/admin/comps/by_sales',
    name: 'admin.comps.by_sales',
    component: () => import(/* webpackChunkName: "admin.comps.by_sales" */ '@/pages/admin/PublicCompsBySales.vue'),
    meta: {
      salesTypeRequired: true
    }
  }, {
    path: '/admin/comps/by_address',
    name: 'admin.comps.by_address',
    component: () => import(/* webpackChunkName: "admin.comps.by_address" */ '@/pages/admin/PublicCompsByAddress.vue'),
    meta: {
      salesTypeRequired: true
    }
  }, {
    path: '/admin/:type/public/:address_detail_pid',
    name: 'admin.comps.public.address',
    component: () => import(/* webpackChunkName: "admin.comps.public.address" */ '@/pages/admin/AddressPublicComps.vue')
  }
]

export default routes.map((route) => ({
  ...route,
  meta: {
    ...route.meta,
    layout: 'admin',
    requiresAdmin: true
  }
}))
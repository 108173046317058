export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/pages/Dashboard.vue'),
    meta: {
      layout: 'dashboard',
      requiresAuth: true
    }
  }, {
    path: '/jobs',
    name: 'jobs',
    component: () => import('@/pages/Jobs.vue'),
    meta: {
      layout: 'dashboard',
      requiresAuth: true
    }
  }, {
    path: '/reports',
    name: 'reports',
    component: () => import('@/pages/Reports.vue'),
    meta: {
      layout: 'dashboard',
      requiresAuth: true
    }
  }, {
    path: '/sales',
    name: 'sales',
    component: () => import('@/pages/Sales.vue'),
    meta: {
      layout: 'dashboard',
      requiresAuth: true
    }
  }, {
    path: '/comp_reviews',
    name: 'comp_reviews',
    component: () => import('@/pages/CompReviews.vue'),
    meta: {
      layout: 'dashboard',
      salesTypeRequired: true,
      requiresAuth: true
    }
  }, {
    path: '/activities_logs',
    name: 'activities_logs',
    component: () => import('@/pages/ActivitiesLogs.vue'),
    meta: {
      layout: 'dashboard',
      requiresAuth: true
    }
  }, {
    path: '/files_backups',
    name: 'files_backups',
    component: () => import('@/pages/FilesBackups.vue'),
    meta: {
      layout: 'dashboard',
      requiresAuth: true
    }
  }, {
    path: '/users',
    name: 'users.list',
    component: () => import('@/pages/UsersList.vue'),
    meta: {
      layout: 'dashboard',
      requiresAuth: true,
      requiresCompanyAdmin: true
    }
  }, {
    path: '/addresses',
    name: 'addresses.list',
    component: () => import('@/pages/AddressesList.vue'),
    meta: {
      layout: 'dashboard',
      requiresAuth: true
    }
  },
]
import api from '@/api'

export default {
  namespaced: true,

  state: {
    overlayCodes: [],
    zoneCodes: {
      vic: [],
      nsw: []
    },
    heritageCodes: [],
    landUseTypes: []
  },

  mutations: {
    SET_CODES(state, data) {
      state.zoneCodes = data.zoneCodes
      state.overlayCodes = data.overlayCodes
      state.heritageCodes = data.heritageCodes
      state.landUseTypes = data.landUseTypes
    }
  },

  actions: {
    getCoverageCodes: (_, {
      type,
      address_detail_pids,
      gnaf_property_pids,
      state_abbreviation
    }) => {
      return api.get('/zoning_and_overlays/coverage_codes', {
        params: {
          type,
          address_detail_pids,
          gnaf_property_pids,
          state_abbreviation
        }
      })
    },
    getCodes: async ({ commit }) => {
      try {
        const response = await api.get('zoning_and_overlays/codes')

        commit('SET_CODES', response.data)
      } catch (_) {
        // do nothing
      }
    }
  },

  getters: {
    getZoneByCode: (state) => (type, code, state_abbreviation) => {
      if (type === 'zone') {
        if (state_abbreviation === 'VIC') {
          return state.zoneCodes.vic.find((c) => c.code === code)
        } else if (state_abbreviation === 'NSW') {
          return state.zoneCodes.nsw.find((c) => c.code === code)
        } else {
          return null
        }
      } else if (type === 'overlay') {
        return state.overlayCodes.find((c) => c.code === code)
      } else {
        return null
      }
    }
  }
}
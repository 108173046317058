import format from 'date-fns/format'
import api from '@/api'
import * as turf from '@turf/turf'
import {
  alphabeticalIndex,
  mod
} from '@/helper/utils'
import {
  uniqueValues
} from '@/helper/array'

const getLenthLineStrings = (geom) => {
  let lines = []
  let length = 0
  let point1, point2

  turf.flattenEach(geom, (currentFeature) => {
    // Length array
    const segments = turf.lineSegment(currentFeature)

    for(let features = segments.features, count = features.length, i = 0, started = false, backsteps = 0; i < count - backsteps + 1;) {
      try {
        let direction = started ? 1 : -1
        i += direction
  
        const point11 = turf.point(features[mod(i, count)].geometry.coordinates[0])
        const point12 = turf.point(features[mod(i, count)].geometry.coordinates[1])
        const bearing1 = turf.bearing(point11, point12)
  
        const point21 = turf.point(features[mod(i + 1, count)].geometry.coordinates[0])
        const point22 = turf.point(features[mod(i + 1, count)].geometry.coordinates[1])
        const bearing2 = turf.bearing(point21, point22)
    
        if (!started) {
          backsteps = backsteps + 1
    
          if (Math.abs(bearing1 - bearing2) > 3) {
            direction = 1
            started = true
            point1 = turf.point(features[mod(i + 1, count)].geometry.coordinates[0])
          }
    
          continue
        }
    
        length += turf.length(features[mod(i, count)])
    
        if (Math.abs(bearing1 - bearing2) > 3) {
          point2 = point21
    
          lines = [
            ...lines,
            turf.lineString(
              [point1.geometry.coordinates, point2.geometry.coordinates],
              { length: (length * 1000).toFixed(1) }
            )
          ]
    
          length = 0
          point1 = point12
        }
      } finally {
        // do nothing
      }
    }
  })

  return lines
}

export default {
  namespaced: true,

  state: {
    loading: false,

    // List/Map View
    items: [],
    loadingItems: false,
    total: 0,

    myComps: [],

    loadingCompRevisions: false,
    compRevisions: [],
    selectedComp: null,
    hoveredCompCoord: null,
    mapNavMode: 'search',

    userShortlistModal: false,
    compIdForShortlist: null,
    addingToUserShortlist: false,
    loadingShortlistComps: false,
    loadingUserShortlist: false,
    userShortlist: [],
    shortlistComps: [],
    sledUserShortlistId: null,

    compEditModal: false,
    compUuidForEdit: null,

    loadingCompDetail: false,
    compDetail: null,
    loadingPointComps: false,
    pointComps: null,

    compTransactions: [],
    loadingCompTransactions: false,

    state_abbreviation: null,
    gnafProperties: [],
    selectedGnafProperty: null,
    gnafGeom: null,
    loadingGnafProperties: false,
    gnafPropertiesSource: null
  },
  mutations: {
    SET_LOADING(state, v) { state.loadingItems = v },

    // List/Map items
    SET_ITEMS_LOADING(state, v) { state.loadingItems = v },
    SET_ITEMS(state, v) { state.items = v },
    SET_TOTAL(state, v) { state.total = v },

    SET_MY_COMPS(state, v) { state.myComps = v },

    SET_SELECTED_COMP(state, v) { state.selectedComp = v },
    // SET_LOADING_COMP_REVISIONS(state, v) { state.loadingCompRevisions = v },
    // SET_COMP_REVISIONS(state, v) { state.compRevisions = v },
    SET_COMP_COORD(state, v) { state.hoveredCompCoord = v },
    SET_MAP_NAV_MODE(state, v) { state.mapNavMode = v },

    SET_TYPES(state, v) { state.propertyTypes = v },
    SET_PROPERTIES(state, v) { state.properties = v },

    // Shortlist
    SET_USER_SHORTLIST_MODAL(state, v) { state.userShortlistModal = v },
    SET_COMPID_FOR_SHORTLIST(state, v) { state.compIdForShortlist = v },
    SET_ADDING_TO_SHORTLIST(state, v) { state.addingToUserShortlist = v },
    SET_LOADING_USER_SHORTLIST(state, v) { state.loadingUserShortlist = v },
    SET_USER_SHORTLIST(state, v) { state.userShortlist = v },
    SET_LOADING_SHORTLIST_COMPS(state, v) { state.loadingShortlistComps = v },
    SET_SHORTLIST_COMPS(state, v) { state.shortlistComps = v },
    SET_SELECTED_USERSHORTLIST_ID(state, v) { state.sledUserShortlistId = v },

    // Edit
    SET_EDIT_MODAL(state, v) { state.compEditModal = v },
    SET_COMPUUID_FOR_EDIT(state, v) { state.compUuidForEdit = v },

    SET_LOADING_COMP_DETAIL(state, v) { state.loadingCompDetail = v },
    SET_COMP_DETAIL(state, v) { state.compDetail = v },

    SET_LOADING_COMPS_BY_POINT(state, v) { state.loadingPointComps = v },
    SET_COMPS_SAME_POINT(state, v) { state.pointComps = v },

    // SET_LOADING_COMP_TRANSACTIONS(state, v) { state.loadingCompTransactions = v },
    // SET_COMP_TRANSACTIONS(state, v) { state.compTransactions = v },

    SET_STATE_ABBREVIATION(state, v) { state.state_abbreviation = v },
    SET_LOADING_GNAF_PROPERTIES(state, v) { state.loadingGnafProperties = v },
    SET_GNAF_PROPERTIES(state, properties) {
      if (properties) {
        if (state.state_abbreviation === 'VIC') {
          if (properties[0]?.address) {
            properties.sort((a, b) => {
              if (a.address.full_address < b.address.full_address) {
                return -1
              }
              if (a.address.full_address > b.address.full_address) {
                return 1
              }
              return 0
            })
          }
  
          state.gnafProperties = properties
  
          let geom = null
  
          for(const p of properties) {
            geom = geom ? turf.union(geom, p.geom) : p.geom
          }
  
          state.gnafGeom = geom
        } else if (state.state_abbreviation === 'NSW') {
          state.gnafProperties = properties

          let geom = null

          for(const p of state.gnafProperties.map((p) => p.property)) {
            geom = geom ? turf.union(geom, p.geom) : p.geom
          }

          state.gnafGeom = geom
        }
      }
    },
    SET_SELECTED_GNAF_PROPERTY(state, v) { state.selectedGnafProperty = v },
    SET_GNAF_PROPERTIES_SOURCE(state, v) { state.gnafPropertiesSource = v }
  },
  actions: {
    /**
     * Get comp details
     */
    async getCompDetails({ commit }, {
      compUuid,
      salesType
    }) {
      commit('SET_LOADING_COMP_DETAIL', true)

      try {
        const res = await api.get(`/comps/by_comp_id/${compUuid}`, {
          params: {
            salesType: salesType
          }
        })

        commit('SET_COMP_DETAIL', res.data.item)
      } finally {
        commit('SET_LOADING_COMP_DETAIL', false)
      }
    },

    async getCompsByPoint({ commit }, {
      salesType,
      lat, lng,
      filterQuery
    }) {
      commit('SET_LOADING_COMPS_BY_POINT', true)

      try {
        const res = await api.get('/comps/by_point', {
          params: {
            salesType,
            lat, lng,
            filterQuery
          }
        })

        commit('SET_COMPS_SAME_POINT', res.data.items)
      } finally {
        commit('SET_LOADING_COMPS_BY_POINT', false)
      }
    },

    /**
     * Get gnaf properties
     */
    async getGnafProperties({ state, commit }, {
      address_detail_pids,
      gnaf_property_pids,
      view_pfis,
      contributor_ids,
      parcel_type = 'vm',

      // NSW
      cadids
    }) {
      commit('SET_LOADING_GNAF_PROPERTIES', true)
      commit('SET_GNAF_PROPERTIES', [])

      try {
        const res = await api.get('/gnaf/properties', {
          params: {
            state: state.state_abbreviation,
            address_detail_pids,
            gnaf_property_pids,
            view_pfis,
            contributor_ids,
            parcel_type,

            cadids
          }
        })

        commit('SET_GNAF_PROPERTIES', res.data.properties)
        commit('SET_SELECTED_GNAF_PROPERTY', null)
      } finally {
        commit('SET_LOADING_GNAF_PROPERTIES', false)
      }
    },

    /**
     * 
     * @param {*} _ 
     * @param {*} payload { salesType: 'comps', soldDt: '10/10/2022', page: 1, itemsPerPage: 10 }
     * @returns 
     */
    async getComps({ commit }, payload) {
      commit('SET_ITEMS_LOADING', true)
      commit('SET_SELECTED_COMP', null)

      try {
        const res = await api.get('/comps/search', {
          params: payload,
          signal: payload.signal
        })

        const { items, total } = res.data

        commit('SET_ITEMS', items.map((item) => ({
          ...item,
          expand: false,
          exporting: false,
          unlocking: false
        })))
        commit('SET_TOTAL', total)
      } finally {
        commit('SET_ITEMS_LOADING', false)
      }
    },
    getCompsFromAddressDetailPid(_, { address_detail_pid, salesType }) {
      return api.get(`/comps/by_address_detail_pid/${address_detail_pid}?salesType=${salesType}`)
    },
    getCompTransactions: (_, {
      address_detail_pids
    }) => {
      return api.get('/comps/history', {
        params: {
          address_detail_pids
        }
      })
      // try {
      //   commit('SET_LOADING_COMP_TRANSACTIONS', true)

      //   const res = await api.get(`/comps/history/${address_detail_pid}`)
      //   const { salesItems, leaseItems } = res.data

      //   commit('SET_COMP_TRANSACTIONS', salesType === 'salesComp' ? salesItems : leaseItems)
      // } finally {
      //   commit('SET_LOADING_COMP_TRANSACTIONS', false)
      // }
    },
    getCompRevisions: async (_, { compId, salesType }) => {
      return api.get(`comps/revision-history/${compId}`, {
        params: {
          salesType
        }
      })
      // try {
      //   commit('SET_LOADING_COMP_REVISIONS', true)

      //   const res = await api.get(`comps/revision-history/${compId}`, {
      //     params: {
      //       salesType
      //     }
      //   })

      //   commit('SET_COMP_REVISIONS', res.data.items)
      // } finally {
      //   commit('SET_LOADING_COMP_REVISIONS', false)
      // }
    },
    selectComp({ commit }, comp) {
      commit('SET_SELECTED_COMP', comp)
    },
    hoverComp({ commit }, coord) {
      commit('SET_COMP_COORD', coord)
    },
    setMapNavMode({ commit }, mode) {
      commit('SET_MAP_NAV_MODE', mode)
    },

    /**
     * 
     * @param {*} _ 
     * @param {*} payload { salesType: 'comps', soldDt: '10/10/2022', page: 1, itemsPerPage: 10 }
     * @returns 
     */
    getCompsGroupedByAddress(_, payload) {
      return api.get('/comps/public/group_by_address', {
        params: payload
      })
    },

    addUserShortlist: async ({ commit }, { title, salesType }) => {
      try {
        commit('SET_ADDING_TO_SHORTLIST', true)

        await api.post('shortlist/add_user_shortlist', {
          title,
          salesType
        })
      } finally {
        commit('SET_ADDING_TO_SHORTLIST', false)
      }
    },

    toggleToUserShortlist: async (_, { compId, userShortlistId, isAdd }) => {
      return api.post('shortlist/toggle_to_user_shortlist', {
        compId,
        userShortlistId,
        isAdd
      })
    },

    /* payload
      {
        compId,
        userShortlistId
      }
    */
    deleteShortlist: (_, payload) => {
      return api.post('/shortlist/delete_shortlist', payload)
    },

    getUserShortlist: async ({ commit }, salesType) => {
      try {
        commit('SET_LOADING_USER_SHORTLIST', true)

        const response = await api.get('shortlist/user_shortlist', {
          params: {
            salesType
          }
        })

        commit('SET_USER_SHORTLIST', response.data.items)

        return response.data
      } catch {
        // Do nothing
      } finally {
        commit('SET_LOADING_USER_SHORTLIST', false)
      }
    },

    getShortlistedComps: async ({ commit }, { salesType, userShortlistId }) => {
      try {
        commit('SET_LOADING_SHORTLIST_COMPS', true)
        const response = await api.get('shortlist', {
          params: {
            salesType,
            userShortlistId
          }
        })

        commit('SET_SHORTLIST_COMPS', response.data.items.map((item, i) => {
          return {
            ...item,
            label: String.fromCharCode(65 + i)
          }
        }))

        return response
      } finally {
        commit('SET_LOADING_SHORTLIST_COMPS', false)
      }
    },

    /**
     * Creates a feature collections composed of polygons, centers, multi sale indexes and lengths from gnaf properties
     * and set gnafPropertiesSource state value
     */
    setGnafPropertiesSourceState: ({ commit, state }, {
      polygons = true,
      areasLabels = false,
      multiSaleLabels = false,
      lengthLabels = false,
      gnafPropertyPids = []
    }) => {
      const geom = state.selectedGnafProperty ? state.selectedGnafProperty.geom : state.gnafGeom
      let profileProperties = [], profileAreasLabels = []

      if (geom) {
        const unionPolygon = geom.type === 'Feature' ? geom.geometry : geom
        const unionFeature = geom.type === 'Feature' ? geom : turf.feature(geom)

        if (multiSaleLabels) {
          gnafPropertyPids.forEach((gnaf_property_pid, i) => {
            const profileProperty = state.gnafProperties.find((p) => +p.pfi === +gnaf_property_pid)

            if (profileProperty) {
              profileProperties.push(turf.center(profileProperty.geom, {
                properties: {
                  multi_sale_index: alphabeticalIndex(i, true)
                }
              }))
            }
          })
        }

        if (areasLabels) {
          if (state.compDetail) {
            state.compDetail.profiles.forEach((profile) => {
              const profileProperty = state.gnafProperties.find((p) => p.pfi === profile.address.gnaf_property_pid)
    
              if (profileProperty) {
                profileAreasLabels.push(turf.center(profileProperty.geom, {
                  properties: {
                    area: turf.area(profileProperty.geom).toFixed(1),
                  }
                }))
              }
            })
          } else {
            profileAreasLabels.push(turf.center(geom, {
              properties: {
                area: turf.area(geom).toFixed(1),
              }
            }))
          }
        }

        const featureCollections = [].concat(
          polygons ? [unionFeature] : [],
          profileAreasLabels,
          profileProperties,
          lengthLabels ? getLenthLineStrings(unionPolygon) : []
        )

        commit('SET_GNAF_PROPERTIES_SOURCE', turf.featureCollection(featureCollections))
      } else {
        commit('SET_GNAF_PROPERTIES_SOURCE', null)
      }
    }
  },

  getters: {
    pageCount: (state) => {
      return Math.ceil(state.total / 10)
    },
    isShortlisted: (state) => (id) => {
      return state.shortlist.findIndex((v) => v.comp_id == id) !== -1
    },
    termPrice: () => (item, i, is_gst_excl) => {
      if (item.sale_price && item.terms[i - 1] && item.terms[i - 1][0]) {
        const price = item.sale_price * item.terms[i - 1][0] * 0.01

        if (item.gst_included) {
          return is_gst_excl ? Math.floor(price * 10 / 11) : price
        } else {
          return is_gst_excl ? price : null
        }
      } else {
        return null
      }
    },
    termDate: () => (item, i) => {
      if (item.sale_dt && item.terms[i - 1] && item.terms[i - 1][1]) {
        const [dd, mm, yyyy] = item.sale_dt.split('/')

        return format(new Date(yyyy, +mm - 1 + +item.terms[i - 1][1], dd), 'dd/MM/yyyy')
      } else {
        return null
      }
    },
    gnafSpis: (state) => {
      let v = []

      if (state.state_abbreviation === 'VIC') {
        if (state.selectedGnafProperty) {
          if (state.selectedGnafProperty.parcels) {
            v = uniqueValues(state.selectedGnafProperty.parcels.map((v) => v.spi))
          }
        } else {
          state.gnafProperties.forEach((property) => {
            if (property.parcels) {
              v = uniqueValues(property.parcels.map((v) => v.spi))
            }
          })
        }
      } else if (state.state_abbreviation === 'NSW') {
        v = state.gnafProperties.map((v) => {
          if (v.sectionnum) {
            return `${v.lotnumber}/${v.sectionnum}/${v.plannumber}`
          } else {
            return `${v.lotnumber}/${v.plannumber}`
          }
        })
        v = uniqueValues(v)
      }

      return v
    },
    gnafLandArea: (state) => {
      if (state.selectedGnafProperty) {
        return turf.area(state.selectedGnafProperty.geom)
      } else if (state.gnafGeom) {
        return turf.area(state.gnafGeom)
      } else {
        return null
      }
    }
  }
}
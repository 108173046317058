<template>
  <v-dialog
    :value="value"
    :retain-focus="false"
    persistent
    no-click-animation
    scrollable
    max-width="1400"
  >
    <v-card>
      <v-card-title>
        <slot name="title">
          {{ salesType === 'salesComp' ? 'Edit sales comp details below' : 'Edit lease comp details below' }}
        </slot>
        <v-spacer />
        <v-icon @click="closeEdit">$mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="compDetail" class="pt-2">
        <div class="d-flex align-start justify-center gap-md">
          <v-stepper
            :value="0"
            vertical
            non-linear
            class="py-4 small-icon"
            width="280"
            min-width="280"
            height="auto"
            style="position: sticky; top: 0;"
          >
            <template v-for="(item, i) in steps">
              <v-stepper-step
                :key="`1-${item.id}`"
                :step="item.id"
                class="align-start pl-4"
                :complete="isFormValid(item.id)"
              >
                <div v-if="i === 1">{{  salesType === 'salesComp' ? 'Sales Analysis' : 'Lease Analysis' }}</div>
                <div v-else>{{ item.text }}</div>
                <small v-if="i === 2" class="mt-1">
                  {{ filesLength > 0 ? `${filesLength} files added.` : 'No files added yet' }}
                </small>
              </v-stepper-step>

              <v-stepper-content :key="`2-${item.id}`" :step="item.id" class="mt-n2" style="margin-left: 26px;" />
            </template>
          </v-stepper>

          <v-card
            class="flex-grow-1"
            style="
              min-width: 460px;
              max-width: 700px;
              width: 0px;
            "
          >
            <v-card-text class="pt-2">
              <h4 class="font-weight-bold primary--text mb-1">Address</h4>
              <v-divider></v-divider>
              <div class="mt-2 mb-2">
                <div v-if="salesForm.is_multi_sale">
                  <div
                    v-for="(address, i) in addresses"
                    :key="i"
                    style="font-size: 14px; line-height: 18px;"
                  >{{ alphabeticalIndex(i, true) }}) {{ address.full_address }}</div>
                </div>
                <div v-else-if="address">
                  <div style="font-size: 14px; line-height: 18px;">{{ address.full_address }}</div>
                </div>
              </div>
              <LandBuildingForm
                v-model="landBuildingForm"
                ref="landBuildingForm"
                :is-multi-sale="salesForm.is_multi_sale"
                :addresses="addresses"
                :sales-type="salesType"
                @validity-changed="landBuildingFormValid = $event"
              />

              <!-- Sales form -->
              <v-form ref="form3" v-model="salesFormValid" class="mt-4">
                <p class="font-weight-bold primary--text mb-1">{{ salesType === 'salesComp' ? 'Sales Analysis' : 'Lease Analysis' }}</p>
                <v-divider></v-divider>

                <v-row v-if="salesType === 'salesComp'" dense class="mt-2">
                  <v-col cols="12">
                    <div class="mb-2">
                      <v-checkbox
                        v-model="salesForm.is_offmarket"
                        label="This is an Off Market sale"
                        hide-details
                      />
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="mb-2">
                      <label for="sale_price" class="caption required">Sale Price<span>*</span></label>
                      <CurrencyText
                        id="sale_price"
                        v-model="salesForm.sale_price"
                        dense
                        outlined
                        hide-details
                        prefix="$"
                        :rules="[$rules.required]"
                      />
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="mb-2">
                      <label for="sale_dt" class="caption required">Sale Date<span>*</span></label>
                      <DatePickerText
                        id="sale_dt"
                        v-model="salesForm.sale_dt"
                        dense
                        outlined
                        hide-details
                        :rules="[$rules.required]"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex align-center">
                      <v-checkbox
                        v-model="salesForm.gst_included"
                        label="Price includes GST"
                        hide-details
                        class="mt-0 pt-0"
                      />
                      <v-tooltip right max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon right v-bind="attrs" v-on="on">$mdi-information</v-icon>
                        </template>
                        <span>Please tick this box if the Sale Price entered is GST inclusive.</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <label for="payment_detail_id" class="caption">Payment details</label>
                    <DropdownSelect
                      id="payment_detail_id"
                      v-model="salesForm.payment_detail_id"
                      ky="paymentDetails"
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col v-if="salesForm.payment_detail_id === 3" cols="12">
                    <v-row dense style="margin-bottom: -26px;">
                      <v-col cols="3"></v-col>
                      <v-col cols="3"></v-col>
                      <v-col cols="2" class="caption" style="padding-left: 12px;">$ Value (excl. GST)</v-col>
                      <v-col cols="2" class="caption" style="padding-left: 4px;">$ Value (incl. GST)</v-col>
                      <v-col cols="2" class="caption">Due Date</v-col>
                    </v-row>
                    <div v-for="i in 6" :key="i">
                      <label :for="`term_${i}`" class="caption">{{ i === 1 ? 'Deposit' : `Payment ${i}` }}</label>
                      <v-row dense>
                        <v-col cols="3">
                          <v-text-field
                            :id="`term_${i}`"
                            v-model="salesForm.terms[i - 1][0]"
                            suffix="%"
                            outlined
                            clearable
                            hide-details
                            type="number"
                            hide-spin-buttons
                            min="0"
                            max="100"
                            clear-icon="$mdi-close"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="salesForm.terms[i - 1][1]"
                            suffix="Months"
                            outlined
                            clearable
                            hide-details
                            type="number"
                            hide-spin-buttons
                            min="0"
                            clear-icon="$mdi-close"
                          />
                        </v-col>
                        <v-col cols="2">
                          <NormalField
                            type="price"
                            :value="termPrice(salesForm, i, true)"
                            style="line-height: 40px; padding-left: 8px;"
                          />
                        </v-col>
                        <v-col cols="2">
                          <NormalField
                            type="price"
                            :value="termPrice(salesForm, i, false)"
                            style="line-height: 40px;"
                          />
                        </v-col>
                        <v-col cols="2">
                          <NormalField
                            v-if="salesForm.sale_dt && salesForm.terms[i - 1][1]"
                            :value="termDate(salesForm, i)"
                            style="line-height: 40px;"
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>

                <!-- Tenure type -->
                <div v-if="salesType === 'salesComp'" class="mt-4">
                  <div class="mb-1">
                    <label class="caption required">Tenure Type<span>*</span></label>
                    <v-tooltip right max-width="300">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon right v-bind="attrs" v-on="on">$mdi-information</v-icon>
                      </template>
                      <span>The property has a tenant and lease in place. Lease details must be provided.</span>
                    </v-tooltip>
                  </div>
                  <TenureTypeInput v-model="salesForm.tenure_type_id" />
                </div>
                <!-- End of tenure type -->

                <div v-if="salesType === 'salesComp' && salesForm.tenure_type_id === 2" class="mt-2">
                  <v-row dense class="mb-2">
                    <v-col cols="6">
                      <div>
                        <label for="passing_rent" class="caption required">
                          {{ salesForm.is_multi_sale ? 'Combined Passing Rent' : 'Passing Rent' }}
                          <span>*</span>
                        </label>
                        <div class="d-flex align-center">
                          <CurrencyText
                            id="passing_rent"
                            v-model="salesForm.passing_rent"
                            dense
                            outlined
                            hide-details
                            prefix="$"
                            :rules="[$rules.required]"
                          />
                          <BtnDropdown
                            v-model="salesForm.passing_rent_type"
                            :items="dropdowns.rentTypes"
                            :right="true"
                            height="40"
                          />
                        </div>
                      </div>
                      <v-checkbox v-model="salesForm.is_passing_rent_approx" label="Approx" hide-details class="mt-0 pt-0" />
                    </v-col>
                  </v-row>

                  <template v-if="salesForm.is_multi_sale">
                    <a @click="showIndividualLease = !showIndividualLease" class="userselect-none">
                      {{ showIndividualLease ? '- Show less rent' : '+ Show rent' }}
                    </a>
                    <v-expand-transition>
                      <div v-show="showIndividualLease" class="px-3">
                        <v-tabs
                          v-model="individualLeaseTab"
                          height="32"
                        >
                          <v-tab
                            v-for="(item, i) in addresses"
                            :key="item.address_detail_pid"
                          >
                          {{ alphabeticalIndex(i) }}) {{ item.street_address }}
                          </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="individualLeaseTab" class="mt-2">
                          <v-tab-item
                            v-for="item in addresses"
                            :key="item.address_detail_pid"
                          >
                            <LeaseProfileForm
                              v-model="salesForm.leaseProfiles[item.address_detail_pid]"
                              is-individual
                            />
                          </v-tab-item>
                        </v-tabs-items>
                      </div>
                    </v-expand-transition>
                  </template>
                  <div v-else>
                    <v-checkbox
                      v-model="showRent"
                      label="Show rent"
                      hide-details
                      class="mt-0 pt-0"
                    />
                    <LeaseProfileForm
                      v-if="showRent"
                      v-model="salesForm"
                    />
                  </div>
                </div>

                <LeaseProfileForm
                  v-if="salesType === 'leaseComp'"
                  v-model="salesForm"
                />
              </v-form>
              <!-- End of sales form -->

              <!-- File upload form -->
              <div class="mt-4">
                <p class="font-weight-bold primary--text mb-1">Image and File Upload</p>
                <v-divider></v-divider>
                <FileUploadForm
                  v-model="filesForm"
                  class="mt-3"
                  is-edit
                />
              </div>
              <!-- End of file upload form -->

              <v-form ref="form4" v-model="extraFormValid" class="mt-6">
                <p class="font-weight-bold primary--text mb-1">Comments</p>
                <v-divider></v-divider>
                <div class="mt-2">
                  <label for="extra_info" class="caption">Comments(Optional)</label>
                  <v-textarea
                    v-model="salesForm.extra_info"
                    id="extra_info"
                    rows="6"
                    outlined
                    dense
                    hide-details
                  />
                </div>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card width="400" min-width="340" style="position: sticky; top: 0;">
            <CompMapPreview
              :is-multi-sale="salesForm.is_multi_sale"
              :address="salesForm.is_multi_sale ? addresses : address"
              :zone-codes="zoneCodes"
              :overlay-codes="overlayCodes"
              :building-rate="buildingRate"
              :land-rate="landRate"
            >
              <template #actions>
                <div class="d-flex justify-center gap-md mt-2">
                  <v-btn
                    class="flex-grow-1 primary"
                    depressed
                    @click="handleSubmit1"
                  >Update</v-btn>
                  <v-btn
                    class="flex-grow-1"
                    text
                    @click="closeEdit"
                  >Cancel</v-btn>
                </div>
              </template>
            </CompMapPreview>
          </v-card>
        </div>
      </v-card-text>

      <v-dialog
        v-model="sourceDlg"
        width="460"
        scrollable
        persistent
        no-click-animation
        :retain-focus="false"
      >
        <v-card>
          <v-card-title class="subtitle-2 font-weight-bold py-2 px-4">Please tell us where you sourced the information from.</v-card-title>
          <v-card-text class="px-4">
            <SourceForm
              ref="srcForm"
              v-model="srcForm"
              :loading="saving"
              :show-reviewers="showReviewers"
              @submit="handleSubmit"
              @close="sourceDlg = false"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { alphabeticalIndex } from '@/helper/utils'

import LandBuildingForm from '@/components/forms/LandBuildingForm.vue'
import CompMapPreview from '@/components/CompMapPreview.vue'
import FileUploadForm from '@/components/admin/FileUploadForm.vue'
import LeaseProfileForm from '@/components/forms/LeaseProfileForm.vue'
import SourceForm from '@/components/forms/SourceForm.vue'

import {
  defaultSalesForm,
  defaultLeaseForm,
  defaultAreaForm,
  defaultLandBuildingForm,
  defaultFilesForm,
  defaultSrcForm
} from '@/@propedia/default_forms'

export default {
  props: ['value', 'compUuid', 'salesType', 'showReviewers'],
  components: {
    LandBuildingForm,
    CompMapPreview,
    FileUploadForm,
    LeaseProfileForm,
    SourceForm
  },
  data() {
    return {
      alphabeticalIndex,

      address: null,
      addresses: null,

      salesForm: structuredClone({
        is_multi_sale: false,
        ...defaultSalesForm,
        ...defaultLeaseForm,
        src_of_info_id: null,
        extra_info: null,
        leaseProfiles: {}
      }),
      landBuildingForm: structuredClone({
        ...defaultLandBuildingForm,
        ...defaultAreaForm,
        areaProfiles: {}
      }),
      filesForm: structuredClone({
        ...defaultFilesForm,
        existingImageIds: [],
        existingDocIds: []
      }),
      srcForm: structuredClone(defaultSrcForm),

      landBuildingFormValid: true,
      extraFormValid: true,
      salesFormValid: true,

      zoneCodes: [],
      overlayCodes: [],
      uploadedImages: [],
      uploadedFiles: [],

      showIndividualLease: false,
      individualLeaseTab: 0,
      showRent: false,
      sourceDlg: false,

      steps: [
        { id: 1, text: 'Land, Building' },
        { id: 2, text: '' },
        { id: 3, text: 'Image and File Upload' },
        { id: 4, text: 'Comments' }
      ],

      showMore: false,

      loading: false,
      saving: false,
    }
  },
  computed: {
    ...mapState('property', [
      'compDetail'
    ]),
    ...mapState('dropdown', [
      'dropdowns'
    ]),
    ...mapGetters('property', [
      'termPrice',
      'termDate'
    ]),
    isSalesComp() {
      return this.salesType === 'salesComp'
    },
    gstFactor() {
      return this.salesForm.gst_included ? 10 / 11 : 1
    },
    landRate() {
      if (this.landBuildingForm.is_strata_unit) {
        return 'N/A'
      } else {
        if (this.salesType === 'salesComp' && this.landBuildingForm.land_area && this.salesForm.sale_price) {
          return '$' + (this.salesForm.sale_price / this.landBuildingForm.land_area * this.gstFactor).toFixed(2) + ' PSQM'
        }
        if (this.salesType === 'leaseComp' && this.landBuildingForm.land_area && this.salesForm.commencing_rent) {
          return '$' + (this.salesForm.commencing_rent / this.landBuildingForm.land_area).toFixed(2) + ' PSQM'
        }
      }
      return '-'
    },
    buildingRate() {
      if (this.salesType === 'salesComp' && this.landBuildingForm.building_area && this.salesForm.sale_price) {
        return '$' + (this.salesForm.sale_price / this.landBuildingForm.building_area * this.gstFactor).toFixed(2) + ' PSQM'
      }
      if (this.salesType === 'leaseComp' && this.landBuildingForm.building_area && this.salesForm.commencing_rent) {
        return '$' + (this.salesForm.commencing_rent / this.landBuildingForm.building_area).toFixed(2) + ' PSQM'
      }

      return '-'
    },
    filesLength() {
      return this.filesForm ? this.filesForm.images.length + this.filesForm.pdfs.length : 0
    }
  },
  watch: {
    value(v) {
      if(v) {
        this.getItemDetail()
      }
    }
  },
  methods: {
    ...mapActions('property', ['getCompDetails']),
    ...mapActions('app', ['notifySuccess']),
    ...mapMutations('property', [
      'SET_COMPUUID_FOR_EDIT',
      'SET_EDIT_MODAL'
    ]),
    async getItemDetail() {
      this.loading = true

      try {
        await this.getCompDetails({
          salesType: this.salesType,
          compUuid: this.compUuid
        })
        const v = this.compDetail

        if (v.is_multi_sale) {
          this.addresses = v.profiles.map((p) => p.address)
        } else {
          this.address = v.profiles[0].address
        }

        // Sales form
        if (this.salesType === 'salesComp') {
          for (const k in defaultSalesForm) {
            this.salesForm[k] = v[k]
          }
        } else {
          for (const k in defaultLeaseForm) {
            this.salesForm[k] = v[k]
          }
        }

        if (v.linkedLease) {
          this.showRent = true
          
          for (const k in defaultLeaseForm) {
            this.salesForm[k] = v.linkedLease[k]
          }
        }

        this.salesForm.src_of_info_id = v.src_of_info_id
        this.salesForm.extra_info = v.extra_info

        // Building and land
        for (const k in defaultLandBuildingForm) {
          this.landBuildingForm[k] = v[k]
        }
        for (const k in defaultAreaForm) {
          this.landBuildingForm[k] = v.combined[k]
        }
        if (this.salesForm.is_multi_sale) {
          v.profiles.forEach((profile) => {
            this.landBuildingForm.areaProfiles[profile.address.address_detail_pid] = profile.area ? { ...profile.area } : { ...defaultAreaForm }
            this.salesForm.leaseProfiles[profile.address.address_detail_pid] = profile.lease ? { ...profile.lease } : { ...defaultLeaseForm }
          })
        }

        if (v.images) {
          this.filesForm.images = v.images.map((v, i) => {
            return {
              id: i,
              url: v.path,
              imgId: v.id
            }
          })
          this.filesForm.existingImageIds = v.images.map((v) => v.id)
        } else {
          this.filesForm.images = []
        }
        if (v.docs) {
          this.filesForm.pdfs = v.docs.map((v, i) => {
            return {
              id: i,
              type: v.mimetype,
              url: v.path,
              label: v.label,
              docId: v.id
            }
          })
          this.filesForm.existingDocIds = v.docs.map((v) => v.id)
        } else {
          this.filesForm.pdfs = []
        }

        this.zoneCodes = v.zone_codes
        this.overlayCodes = v.zone_codes
      } finally {
        this.loading = false
      }
    },
    isFormValid(ind) {
      switch(ind) {
        case 1:
          return this.landBuildingFormValid
        case 2:
          return this.salesFormValid
        case 3:
          return true
        case 4:
          return this.extraFormValid
      }
    },
    handleSubmit1() {
      if (
        this.$refs.landBuildingForm.$refs.form.validate() &&
        this.$refs.form3.validate() &&
        this.$refs.form4.validate()
      ) {
        this.sourceDlg = true
      }
    },
    async handleSubmit() {
      if (this.$refs.srcForm.$refs.form.validate()) {
        try {
          this.saving = true

          const formData = new FormData()
          const jsonData = {
            salesType: this.salesType,
            ...this.salesForm,
            showRent: this.showRent,
            ...this.landBuildingForm,
            address_detail_pid: this.address?.address_detail_pid,
            address_detail_pids: this.addresses?.map((a) => a.address_detail_pid),
            ...this.srcForm
          }

          Object.keys(jsonData).forEach((k) => {
            if (jsonData[k] !== null && jsonData[k] !== undefined && jsonData[k] !== "") {
              if (Array.isArray(jsonData[k])) {
                jsonData[k].forEach((v) => {
                  formData.append(`${k}[]`, Array.isArray(v) || typeof v === 'object' ? JSON.stringify(v) : v)
                })
              } else if (typeof jsonData[k] === 'object') {
                formData.append(k, JSON.stringify(jsonData[k]))
              } else {
                formData.append(k, jsonData[k])
              }
            }
          })

          for (const img of this.filesForm.images) {
            if (img.file) {
              formData.append("compImages", img.file, img.file.name)
            }
          }

          const oldImageIds = this.filesForm.images.filter((v) => !v.file).map((v) => v.imgId)
          const oldDocIds = this.filesForm.pdfs.filter((v) => !v.file).map((v) => v.docId)

          // Images
          this.filesForm.existingImageIds.filter((id) => !oldImageIds.includes(id)).forEach((id) => {
            formData.append("deletedImageIds[]", id)
          })

          // Doc
          for (const doc of this.filesForm.pdfs) {
            if (doc.file) {
              formData.append("compDocs", doc.file, `${doc.label}.${doc.file.name.split('.').slice(-1)}`)
            }
          }
          this.filesForm.existingDocIds.filter((id) => !oldDocIds.includes(id)).forEach((id) => {
            formData.append("deletedDocIds[]", id)
          })

          await api.put(`/comps/${this.compUuid}`, formData)

          this.$emit('updated')
          this.notifySuccess('Successfully updated')
        } finally {
          this.saving = false
        }
      }
    },
    closeEdit() {
      this.$emit('close')
      this.SET_COMPUUID_FOR_EDIT(null)
      this.SET_EDIT_MODAL(false)
    }
  }
}
</script>
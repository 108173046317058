<template>
  <div>
    <v-row dense>
      <v-col cols="6">
        <div>
          <label for="commencing_rent" class="caption required">
            Commencing Rent
            <span>*</span>
          </label>
          <div class="d-flex align-center">
            <CurrencyText
              id="commencing_rent"
              v-model="local.commencing_rent"
              dense
              outlined
              hide-details
              prefix="$"
              :rules="(!isIndividual || isOneFieldFilled) ? [$rules.required] : []"
            />
            <BtnDropdown
              v-model="local.commencing_rent_type"
              :items="dropdowns.rentTypes"
              :right="true"
              height="40"
            />
          </div>
        </div>
        <v-checkbox v-model="local.is_commencing_rent_approx" label="Approx" hide-details class="mt-0 pt-0" />
      </v-col>
      <v-col cols="6">
        <div>
          <label for="lease_date" class="caption required">Lease Date (Commencement Date)<span>*</span></label>
          <DatePickerText
            id="lease_date"
            v-model="local.lease_dt"
            dense
            outlined
            hide-details
            :rules="(!isIndividual || isOneFieldFilled) ? [$rules.required] : []"
          />
        </div>
        <v-checkbox
          v-model="local.is_lease_dt_approx"
          label="Approx"
          hide-details
          class="mt-0 pt-0"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <label for="lease_terms" class="caption">Lease Terms (Optional)</label>
        <div class="d-flex align-center">
          <v-text-field
            id="lease_terms"
            v-model="local.lease_terms"
            type="number"
            hide-spin-buttons
            dense
            outlined
            hide-details
            min="0"
          />
          <BtnDropdown
            v-model="local.lease_term_type"
            :items="dropdowns.leaseTermTypes"
            :right="true"
            height="40"
          />
        </div>
        <v-checkbox
          v-model="local.is_lease_terms_approx"
          label="Approx"
          hide-details
          class="mt-0 pt-0"
        />
      </v-col>
      <v-col cols="6">
        <label for="outgoings" class="caption">Outgoings (Optional)</label>
        <div class="d-flex align-center">
          <CurrencyText
            id="outgoings"
            v-model="local.outgoings"
            dense
            outlined
            prefix="$"
            hide-details
          />
        </div>
        <v-checkbox
          v-model="local.is_outgoings_approx"
          label="Approx"
          hide-details
          class="mt-0 pt-0"
        />
      </v-col>
      <v-col cols="6">
        <label for="irrecoverable_outgoings" class="caption">Irrecoverable outgoings (Optional)</label>
        <div class="d-flex align-center">
          <CurrencyText
            id="irrecoverable_outgoings"
            v-model="local.irrecoverable_outgoings"
            dense
            outlined
            prefix="$"
            hide-details
          />
        </div>
        <v-checkbox
          v-model="local.is_irrecoverable_outgoings_approx"
          label="Approx"
          hide-details
          class="mt-0 pt-0"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <label for="lease_option" class="caption">Lease Option (Optional)</label>
        <v-textarea
          v-model="local.lease_option"
          id="lease_option"
          rows="2"
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col cols="6">
        <label for="lease_review" class="caption">Lease Review (Optional)</label>
        <v-textarea
          v-model="local.lease_review"
          id="lease_review"
          rows="2"
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <a @click="showMore = !showMore" class="userselect-none">
          {{ showMore ? '- Show less' : '+ Show more' }}
        </a>
        <v-expand-transition>
          <div v-show="showMore">
            <v-row dense>
              <v-col cols="6">
                <label for="rent_incentives" class="caption">Rent Incentives (Optional)</label>
                <v-text-field
                  id="rent_incentives"
                  v-model="local.rent_incentives"
                  type="number"
                  hide-spin-buttons
                  dense
                  outlined
                  hide-details
                />
              </v-col>
              <v-col cols="6">
                <label for="tenant" class="caption">Tenant (Optional)</label>
                <v-text-field
                  id="tenant"
                  v-model="local.tenant"
                  dense
                  outlined
                  hide-details
                />
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        commencing_rent: null,
        commencing_rent_type: 'Net',
        is_commencing_rent_approx: true,
        is_lease_dt_approx: true,
        lease_dt: null,
        lease_terms: null,
        lease_term_type: 'Years',
        outgoings: null,
        is_outgoings_approx: true,
        lease_option: null,
        lease_review: null,
        rent_incentives: null,
        tenant: null,
      })
    },
    isIndividual: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMore: false,
    }
  },
  computed: {
    ...mapState('dropdown', ['dropdowns']),
    local() {
      return this.value
    },
    isOneFieldFilled() {
      return !!(this.local.commencing_rent ||
        this.local.lease_dt ||
        this.local.lease_terms ||
        this.local.lease_option ||
        this.local.outgoings ||
        this.local.irrecoverable_outgoings ||
        this.local.lease_review ||
        this.local.rent_incentives ||
        this.local.tenant)
    }
  }
}
</script>
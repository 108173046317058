<template>
  <v-select
    :id="id"
    hide-details
    v-bind="$attrs"
    :value="value"
    :items="dropdowns[ky]"
    :item-value="itemValue ? itemValue : 'id'"
    :item-text="itemText ? itemText : 'name'"
    clearable
    clear-icon="$mdi-close"
    @change="$emit('input', $event)"
  />
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['value', 'id', 'ky', 'itemValue', 'itemText'],

  computed: {
    ...mapState('dropdown', ['dropdowns'])
  }
}
</script>
<template>
  <v-btn-toggle
    :value="value"
    v-bind="$attrs"
    mandatory
    color="primary"
    @change="$emit('input', $event)"
  >
    <v-btn
      v-for="(item, i) in dropdowns.tenureTypes"
      :key="i"
      :value="item.id"
      height="36"
    >
      {{ item.name }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['value'],
  computed: {
    ...mapState('dropdown', ['dropdowns'])
  }
}
</script>

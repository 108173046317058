module.exports = {
  removeEmpty: (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v)) // eslint-disable-line no-unused-vars
  },
  roundTo: (v, digits = 2) => {
    const multiplicator = Math.pow(10, digits);

    return v ? Math.round(multiplicator * v) / multiplicator : 'N/A'
  },
  getInitials: (v) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
    let initials = [...v.matchAll(rgx)] || []

    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase()

    return initials
  },
  alphabeticalIndex: (ind, isCapital = false) => {
    return String.fromCharCode((isCapital ? 'A' : 'a').charCodeAt() + ind)
  },
  mod: (n, m) => ((n % m) + m) % m
}
<template>
  <div :class="{'d-flex align-center': inline}" v-bind="$attrs">
    <div
      v-if="label"
      class="caption white-space-no-wrap"
      :class="{'mr-1': inline}"
      :style="`width: ${labelWidth ? labelWidth + 'px' : ''}`"
    >{{ label }}</div>
    <div>
      <v-icon v-if="!unlocked" small>$mdi-lock</v-icon>
      <template v-else>
        <slot name="value">
          <div :class="{'white-space-no-wrap': noWrap}" :style="customStyle">{{ val ?? '-' }}</div>
        </slot>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  props: {
    type: {
      type: String,
      default: 'normal'
    },
    value: {
      type: [String, Number]
    },
    ky: {
      type: String
    },
    label: {
      type: String
    },
    unlocked: {
      type: Boolean,
      default: true
    },
    inline: {
      type: Boolean,
      default: false
    },
    labelWidth: {
      type: String
    },
    noWrap: {
      type: Boolean,
      default: true
    },
    customStyle: {
      type: String
    }
  },
  computed: {
    ...mapState('dropdown', ['dropdowns']),
    ...mapGetters('dropdown', ['dropdownItemName']),
    val() {
      switch(this.type) {
        case 'number':
          return this.value ? this.$options.filters.number(this.value) : null
        case 'price':
          return this.value ? this.$options.filters.currency(this.$options.filters.number(this.value)) : null
        case 'dropdown':
          return this.dropdownItemName(this.value, this.ky)
        case 'area':
          return this.value ? `${this.$options.filters.area(this.$options.filters.number(this.value))}` : null
        case 'percentage':
          return this.value ? `${this.$options.filters.percentage(this.$options.filters.number(this.value))}` : null
        case 'month-format':
          return this.value ? `${this.$options.filters['month-format'](this.value)}` : null
        case 'date-format':
          return this.value ? `${this.$options.filters['date-format'](this.value)}` : null
        default:
          return this.value
      }
    }
  }
}
</script>
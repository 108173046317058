export default [
  {
    path: '/dev/dashboard',
    name: 'dev.dashboard',
    component: () => import(/* webpackChunkName: "dev.server.resources" */ '@/pages/dev/Dashboard.vue'),
    meta: {
      layout: 'dev',
      requiresDev: true
    }
  }, {
    path: '/dev/home',
    name: 'dev.home',
    component: () => import(/* webpackChunkName: "view" */ '@/pages/DevPanel.vue'),
    meta: {
      layout: 'dev',
      requiresDev: true
    }
  }
]
<template>
  <v-btn icon class="mr-1" @click="toggleAdminDrawer">
    <v-icon>$mdi-menu</v-icon>
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('app', ['toggleAdminDrawer'])
  }
}
</script>
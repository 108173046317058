export default [
  {
    path: '/me/submissions',
    name: 'me.submissions',
    component: () => import(/* webpackChunkName: "me.submissions" */ '@/pages/me/MySubmissions.vue'),
    meta: {
      for: 'me.comps',
      layout: 'default',
      navigation: 'me',
      requiresAuth: true,
      salesTypeRequired: true
    }
  }, {
    path: '/me/shortlist',
    name: 'me.shortlist',
    component: () => import(/* webpackChunkName: "me.shortlist" */ '@/pages/me/MyShortlist.vue'),
    meta: {
      for: 'me.comps',
      layout: 'default',
      navigation: 'me',
      requiresAuth: true
    }
  }, {
    path: '/me/comps',
    name: 'me.comps',
    component: () => import(/* webpackChunkName: "me.comps" */ '@/pages/me/MyComps.vue'),
    meta: {
      for: 'me.comps',
      layout: 'default',
      navigation: 'me',
      requiresAuth: true,
      salesTypeRequired: true
    }
  }, {
    path: '/me/reporting',
    name: 'me.reporting',
    component: () => import(/* webpackChunkName: "me.reporting" */ '@/pages/me/MyReportings.vue'),
    meta: {
      layout: 'default',
      for: 'me.reporting',
      navigation: 'me',
      requiresAuth: true
    }
  }
]
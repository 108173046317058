<template>
  <v-menu
    transition="slide-y-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :id="id"
        :value="value"
        outlined
        dense
        hide-details
        placeholder="dd/MM/yyyy"
        v-mask="'##/##/####'"
        prepend-inner-icon="$mdi-calendar-month"
        clearable
        clear-icon="$mdi-close"
        v-bind="{ ...$attrs }"
        @click:prepend-inner="on.click"
        @input="$emit('input', $event)"
      />
    </template>
    <v-date-picker
      :value="parseDate(value)"
      no-title
      scrollable
      color="primary"
      @change="onPickerChanged"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
const formatDate = (date) => {
  if (!date) return null

  const [year, month, day] = date.split('-')
  return `${day}/${month}/${year}`
}

export default {
  name: 'date-picker-text',
  props: {
    value: {
      type: String,
      default: null
    },
    id: {
      type: String
    }
  },
  data() {
    return {
      formatDate,
      date: null
    }
  },
  methods: {
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      try {
        const parsed = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`

        if (parsed === 'Invalid Date') return null

        return parsed
      } catch (err) {
        return null
      }
    },
    onPickerChanged(v) {
      this.$emit('input', this.formatDate(v))
    }
  }
}
</script>
import api from '@/api'

export default {
  namespaced: true,

  state: {
    loadingUser: false,
    user: null
  },
  mutations: {
    SET_USER_LOADING(state, loading) { state.loadingUser = loading },
    SET_USER(state, user) { state.user = user },
  },
  actions: {
    async getUsers() {
      return api.get('users')
    },
    async updateUser(_, payload) {
      return api.put(`users/${payload.id}`, payload)
    },
    async getUser({ commit }, id) {
      try {
        commit('SET_USER_LOADING', true)

        const res = await api.get(`users/${id}`)
        
        commit('SET_USER', res.data.user)
      } finally {
        commit('SET_USER_LOADING', false)
      }
    }
  }
}
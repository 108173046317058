import api from '@/api'

export default {
  namespaced: true,

  state: {
    addressSearch: null,
    fullAddressCandidates: [],
    suburbCandidates: []
  },
  mutations: {
    SET_ADDRESS_SEARCH(state, v) { state.addressSearch = v },
    SET_FULLADDRESS_CANDIDATES(state, v) { state.fullAddressCandidates = v },
    SET_SUBURB_CANDIDATES(state, v) { state.suburbCandidates = v }
  },

  actions: {
    getAddress: async (_, {
      address_detail_pid
    }) => {
      return api.get(`addresses/${address_detail_pid}`)
    },
    findAddresses: async (_, {
      state,
      gnaf_property_pids
    }) => {
      return api.get('addresses', {
        params: {
          state,
          gnaf_property_pids
        }
      })
    },
    searchCandidates: async ({ commit }, {
      keyword,
      showFullAddress = false,
      showSuburb = false
    }) => {
      if (showFullAddress) {
        const res = await api.get('addresses/full_address_candidates', {
          params: {
            keyword
          }
        })

        commit('SET_FULLADDRESS_CANDIDATES', res.data.candidates)
      }
      if (showSuburb) {
        const res = await api.get('addresses/suburb_candidates', {
          params: {
            keyword
          }
        })

        commit('SET_SUBURB_CANDIDATES', res.data.candidates)
      }
    },
    mockSuburbCandidates: async ({ commit }, {
      address_detail_pid,
      suburb
    }) => {
      if (address_detail_pid) {
        const res = await api.get('addresses/full_address_candidates/by_address_detail_pid', {
          params: {
            address_detail_pid
          }
        })

        commit('SET_FULLADDRESS_CANDIDATES', res.data.candidates)
      } else if (suburb) {
        const res = await api.get('addresses/suburb_candidates/by_suburb', {
          params: {
            suburb
          }
        })

        commit('SET_SUBURB_CANDIDATES', res.data.candidates)
      } else {
        return false
      }
    },
    async updateManualAddressStatus(_, {
      manualAddress,
      status
    }) {
      return api.post('/addresses/manual/status', {
        status,
        uuid: manualAddress.uuid
      })
    }
  },

  getters: {
    candidates: (state) => (showFullAddress = true, showSuburb = false) => {
      const fullAddressCandidates = state.fullAddressCandidates.map((item) => ({
        value: item.address_detail_pid,
        type: 'full_address',
        text: item.full_address
      }))
      const suburbCandidates = state.suburbCandidates.map((item) => ({
        value: item.display,
        type: 'suburb',
        text: item.display
      }))

      if (showFullAddress && !showSuburb) {
        return fullAddressCandidates
      } else if (!showFullAddress && showSuburb) {
        return suburbCandidates
      } else {
        return [
          { header: 'Full Address' },
          ...fullAddressCandidates,
          { header: 'Suburb' },
          ...suburbCandidates
        ]
      }
    },
    full_address: () => (address) => {
      let flat = null
      let level = null
      let number = null
      let address_line1 = null

      if (address.flat_number) {
        flat = `${address.flat_type || ''} ${address.flat_number_prefix || ''}${address.flat_number || ''}${address.flat_number_suffix || ''}`
      }
      if (address.level_number) {
        level = `${address.level_type || ''} ${address.level_number_prefix || ''}${address.level_number || ''}${address.level_number_suffix || ''}`
      }
      const lot = `${address.lot_number_prefix || ''}${address.lot_number || ''}${address.lot_number_suffix || ''}`
      const number_first_formatted = `${address.number_first_prefix || ''}${address.number_first || ''}${address.number_first_suffix || ''}`
      const number_last_formatted = `${address.number_last_prefix || ''}${address.number_last || ''}${address.number_last_suffix || ''}`
      const street = `${address.street_name ?? '-'} ${address.street_type_code || ''} ${address.street_suffix_code || ''}`

      if (!number_first_formatted) {
        number = lot
      } else if (number_last_formatted) {
        number = `${number_first_formatted}-${number_last_formatted}`
      } else {
        number = number_first_formatted
      }
      if (flat) address_line1 = `${flat}, `
      if (level) address_line1 = `${address_line1 || ''}${level}, `

      const street_address = address_line1 ? `${address_line1}${number} ${street}` : `${number} ${street}`
      const full_address = `${street_address} ${address.locality_name ?? '-'} ${address.state_abbreviation ?? '-'} ${address.postcode ?? '-'}`.trim()

      return full_address
    }
  }
}
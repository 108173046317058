<template>
  <img
    src="/images/logo/logo_w_dot_800x168_latest.svg"
    class="cursor-pointer"
    @click="$router.push('/')"
  />
</template>
<script>
export default {
  props: {
    // height: {
    //   type: String,
    //   required: false,
    //   default: () => '100%'
    // }
  }
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

img {
  width: 200px;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  img {
    width: 150px;
  }
}
</style>
export default {
  "mapbox_streets": {
    "url": "mapbox://mapbox.mapbox-streets-v7",
    "type": "vector"
  },
  "mapbox_satellite": {
    "url": "mapbox://mapbox.satellite",
    "type": "raster"
  },
  "parcel_view_vic": {
    "tiles": ["https://tiles.propedia.com.au/data/parcel_view_vic/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 15
  },
  "vm_property_view": {
    "tiles": ["https://tiles.propedia.com.au/data/vm_property_view/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 15
  },
  "lot_nsw": {
    "tiles": ["https://tiles.propedia.com.au/data/lot_nsw/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "plan_overlay_vic": {
    "tiles": ["https://tiles.propedia.com.au/data/plan_overlay_vic/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "plan_zone_vic": {
    "tiles": ["https://tiles.propedia.com.au/data/plan_zone_vic/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 13
  },
  "plan_zone_nsw": {
    "tiles": ["https://tiles.propedia.com.au/data/plan_zone_nsw/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "flood": {
    "tiles": ["https://tiles.propedia.com.au/data/flood/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "lot_size": {
    "tiles": ["https://tiles.propedia.com.au/data/lot_size/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "fsr": {
    "tiles": ["https://tiles.propedia.com.au/data/fsr/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "landslide": {
    "tiles": ["https://tiles.propedia.com.au/data/landslide/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "heritage": {
    "tiles": ["https://tiles.propedia.com.au/data/heritage/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "localities": {
    "tiles": ["https://tiles.propedia.com.au/data/localities/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "lga": {
    "tiles": ["https://tiles.propedia.com.au/data/lga/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "hob": {
    "tiles": ["https://tiles.propedia.com.au/data/hob/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "easement": {
    "tiles": ["https://tiles.propedia.com.au/data/easement/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 13,
    "minzoom": 0
  },
  "bushfire": {
    "tiles": ["https://tiles.propedia.com.au/data/bushfire/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "ugb": {
    "tiles": ["https://tiles.propedia.com.au/data/ugb/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "land_use": {
    "tiles": ["https://tiles.propedia.com.au/data/land_use/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "psp": {
    "tiles": ["https://tiles.propedia.com.au/data/psp/{z}/{x}/{y}.pbf"],
    "type": "vector",
    "maxzoom": 14
  },
  "highlighted_properties": {
    "type": "geojson",
    "data": null
  },
  "comp_index": {
    "type": "vector",
    "tiles": [],
    "cluster": true,
    "clusterMaxZoom": 14
  },
  "comp_index_marker": {
    "type": "geojson",
    "data": null
  },
  "shortlist": {
    "type": "geojson",
    "data": null
  },
  "marker": {
    "type": "geojson",
    "data": null
  },
  "point": {
    "type": "geojson",
    "data": null
  },
  // polygon for selected locality boundary
  "polygon": {
    "type": "geojson",
    "data": null
  },
}
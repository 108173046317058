<template>
  <div>
    <v-card-text v-if="!gnafPropertyPids">
      To activate the mini map below, please enter an address in Sales Analysis section
    </v-card-text>

    <div id="map" style="position: relative; width: 100%; height: 300px;">
      <v-overlay
        absolute
        :value="!gnafPropertyPids"
      >
        <v-icon>$mdi-lock</v-icon>
      </v-overlay>
    </div>

    <v-card-text>
      <v-row v-if="isMultiSale && address.length || !isMultiSale && address" dense class="mt-1">
        <v-col>
          <ZoneOverlayField
            type="zone"
            label="Zone"
            :addresses="isMultiSale ? address : [address]"
            :state_abbreviation="isMultiSale ? address[0].state_abbreviation : address.state_abbreviation"
          />
        </v-col>
        <v-col v-if="address.state_abbreviation === 'VIC'">
          <ZoneOverlayField
            type="overlay"
            label="Overlay"
            :addresses="isMultiSale ? address : [address]"
            :state_abbreviation="isMultiSale ? address[0].state_abbreviation : address.state_abbreviation"
          />
        </v-col>
      </v-row>

      <v-row v-if="address" dense>
        <v-col>
          <div class="d-flex align-center caption">
            <span>Land Rate</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Result from dividing the sale price (Excludes GST) by the total square metres of the land</span>
            </v-tooltip>
          </div>
          <div>{{ landRate }}</div>
        </v-col>
        <v-col>
          <div class="d-flex align-center caption">
            <span>Building Rate</span>
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon right v-bind="attrs" v-on="on">$mdi-information</v-icon>
              </template>
              <span>Result from dividing the sale price (Excludes GST) by the total square metres of lettable area of the building</span>
            </v-tooltip>
          </div>
          <div>{{ buildingRate }}</div>
        </v-col>
      </v-row>

      <slot name="actions" />
    </v-card-text>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import mapboxgl from 'mapbox-gl'
import * as turf from '@turf/turf'

// import mapStyle from '@/config/default.json'

import ZoneOverlayField from '@/components/attributes/ZoneOverlay'

export default {
  props: [
    'isMultiSale',
    'salesType',
    'address',
    'landRate',
    'buildingRate'
  ],
  components: {
    ZoneOverlayField
  },
  data() {
    return {
      $map: null
    }
  },
  computed: {
    ...mapState('property', [
      'gnafPropertiesSource'
    ]),
    ...mapGetters('app', [
      'mapStyle'
    ]),
    gnafPropertyPids() {
      if (this.isMultiSale && this.address.length) {
        return this.address.map((p) => p.gnaf_property_pid)
      } else if (!this.isMultiSale && this.address) {
        return [this.address.gnaf_property_pid]
      }

      return null
    }
  },
  watch: {
    address() {
      this.handleAddressesChange()
    }
  },
  created() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN
  },
  mounted() {
    this.initMap()
    this.handleAddressesChange()
    // if (this.address) {
    //   this.onAddressSelected()
    // }
  },
  methods: {
    ...mapActions('property', [
      'setGnafPropertiesSourceState',
      'getGnafProperties',
    ]),
    ...mapMutations('property', [
      'SET_STATE_ABBREVIATION'
    ]),
    async initMap() {
      await this.$nextTick()

      const mapContaienr = document.getElementById('map')

      this.$map = new mapboxgl.Map({
        container: mapContaienr,
        style: this.mapStyle('add_new_address'),
        zoom: 15,
        center: [144.946457, -37.840935],
        attributionControl: false
      });
    },

    /**
     * Set highlighted_properties source from state
     */
     setGnafPropertiesSource(visible = true) {
      this.$map.getSource('highlighted_properties').setData(visible ? this.gnafPropertiesSource : null)
    },

    /**
     * Toggle layers visibility
     */
     setGnafPropertiesLayersVisibility(visibility) {
      if (this.$map) {
        [
          'property_lines',
          'property_lines_fill',
          'property_length_labels',
          'property_area_label',
          ...(this.isMultiSale ? ['property_multi_sale_label'] : [])
        ].forEach((l) => {
          this.$map.setLayoutProperty(l, 'visibility', visibility)
        })
      }
    },

    /**
     * Bound gnaf properties
     */
     boundGnafProperties() {
      this.gnafPropertiesSource && this.$map.fitBounds(
        turf.bbox(this.gnafPropertiesSource),
        {
          duration: 300,
          padding: { left: 10, bottom: 10, top: 10, right: 10 }
        }
      )
    },
    handleAddressesChange() {
      if (this.gnafPropertyPids) {
        this.SET_STATE_ABBREVIATION(this.isMultiSale ? this.address[0].state_abbreviation : this.address.state_abbreviation)
        this.getGnafProperties({
          gnaf_property_pids: this.gnafPropertyPids
        }).then(() => {
          this.setGnafPropertiesSourceState({
            areasLabels: true,
            multiSaleLabels: this.isMultiSale ? true : false,
            gnafPropertyPids: this.gnafPropertyPids
          })
          this.setGnafPropertiesSource()
          this.setGnafPropertiesLayersVisibility('visible')
          this.boundGnafProperties()
        })
      } else {
        this.setGnafPropertiesLayersVisibility('none')
      }
    },
  }
}
</script>
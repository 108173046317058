<template>
  <v-form ref="form" v-model="valid" @input="$emit('validity-changed', $event)">
    <div class="d-flex align-end gap-md mb-1">
      <h4 class="font-weight-bold primary--text mb-0">Land & Building</h4>
      <AreaProfileMenu
        v-if="!isMultiSale"
        :selected-profile="selectedProfile"
        :profiles="profiles"
        :sales-type="salesType"
        @input="handleProfileSelect"
      />
    </div>
    <v-divider></v-divider>
    <div class="mb-4 mt-2">
      <label for="type_ids" class="caption required">Property Type<span>*</span></label>
      <PropertyTypeMultiSelect
        id="type_ids"
        v-model="local.type_ids"
        dense
        outlined
        hide-details
        :rules="[$rules.length]"
        @input="update('type_ids', $event)"
      />
    </div>
    <div v-if="salesType === 'salesComp'" class="mb-2">
      <div class="">Development (Optional)</div>
      <v-checkbox
        v-model="local.with_development_potential"
        label="With Development Potential"
        hide-details
        class="mt-0 pt-0"
        @change="update('with_development_potential', $event)"
      />
      <v-checkbox
        v-model="local.with_permit_approved"
        label="With Permit Approved"
        hide-details
        class="mt-0 pt-0"
        @change="update('with_permit_approved', $event)"
      />
    </div>
    <div class="d-flex align-center mb-2">
      <v-checkbox
        v-model="local.is_strata_unit"
        label="Strata/Unit"
        hide-details
        class="mt-0 pt-0"
        @change="update('is_strata_unit', $event)"
      />
      <v-tooltip right max-width="300">
        <template v-slot:activator="{ on, attrs }">
          <v-icon right v-bind="attrs" v-on="on">$mdi-information</v-icon>
        </template>
        <span>A Unit that is a part of a property and shares ownership with others of the common areas of the building</span>
      </v-tooltip>
    </div>

    <AreaProfileForm
      :value="localAreaForm"
      :sales-type="salesType"
      :is_strata_unit="local.is_strata_unit"
      :is-lease-profile-filled="leaseSelected"
      :is-multi-label="isMultiSale"
      @input="update('area_form', $event)"
    />

    <div v-if="isMultiSale" class="mt-2">
      <a @click="showIndividualAreas = !showIndividualAreas" class="userselect-none">
        {{ showIndividualAreas ? '- Show less individual land & building area' : '+ Add individual land & building area' }}
      </a>
      <v-expand-transition>
        <div v-show="showIndividualAreas" class="px-3">
          <v-tabs
            v-model="tab"
            height="32"
          >
            <v-tab
              v-for="(item, i) in addresses"
              :key="item.address_detail_pid"
            >{{ alphabeticalIndex(i, true) }}) {{ item.street_address }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="mt-2">
            <v-tab-item
              v-for="item in addresses"
              :key="item.address_detail_pid"
            >
              <AreaProfileForm
                v-model="local.areaProfiles[item.address_detail_pid]"
                :sales-type="salesType"
                :address_detail_pid="item.address_detail_pid"
                :is_strata_unit="local.is_strata_unit"
                :is-lease-profile-filled="isLeaseProfileFilled(item.address_detail_pid)"
                @input="updateAreaProfileForm(item.address_detail_pid, $event)"
              />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-expand-transition>
    </div>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'

import PropertyTypeMultiSelect from '@/components/PropertyTypeMultiSelect'
import AreaProfileMenu from '@/components/AreaProfileMenu'
import AreaProfileForm from '@/components/forms/AreaProfileForm'

import { alphabeticalIndex } from '@/helper/utils'

export default {
  props: [
    'value',
    'salesType',
    'isMultiSale',
    'address',
    'addresses',
    'leaseProfiles',
    'leaseSelected'
  ],
  components: {
    PropertyTypeMultiSelect,
    AreaProfileMenu,
    AreaProfileForm
  },

  data() {
    return {
      alphabeticalIndex,

      valid: true,
      showMoreAreas: false,
      showIndividualAreas: false,
      tab: 0,
      profiles: [],
      selectedProfile: null
    }
  },

  watch: {
    salesType() {
      this.selectedProfile = null
    },
    address() {
      // Get area profiles for address
      if (!this.isMultiSale && this.address) {
        this.getCompsFromAddressDetailPid({
          address_detail_pid: this.address.address_detail_pid,
          salesType: this.salesType
        }).then((res) => {
          this.profiles = res.data.items
        })
      }
    }
  },

  computed: {
    local() {
      return {
        ...this.value,
        ...this.localAreaForm
      }
    },
    localAreaForm() {
      return {
        building_area: this.value.building_area,
        building_area_type: this.value.building_area_type,
        land_area: this.value.land_area,
        area_1_type_id: this.value.area_1_type_id,
        area_1: this.value.area_1,
        area_2_type_id: this.value.area_2_type_id,
        area_2: this.value.area_2,
        area_3_type_id: this.value.area_3_type_id,
        area_3: this.value.area_3,
        area_4_type_id: this.value.area_4_type_id,
        area_4: this.value.area_4
      }
    },
    isSalesComp() {
      return this.salesType === 'salesComp' ? true : false
    },
  },

  methods: {
    ...mapActions('property', ['getCompsFromAddressDetailPid']),
    isLeaseProfileFilled(address_detail_pid) {
      if (!this.leaseProfiles) {
        return false
      }

      return !!(this.leaseProfiles[address_detail_pid].commencing_rent && this.leaseProfiles[address_detail_pid].lease_dt)
    },
    handleProfileSelect(item) {
      this.selectedProfile = item
      this.$emit('input', {
        ...this.local,
        'type_ids': item.type_ids,
        'is_strata_unit': item.is_strata_unit,
        'with_development_potential': item.with_development_potential,
        'with_permit_approved': item.with_permit_approved,

        'building_area': item.building_area,
        'building_area_type': item.combined.building_area_type,
        'land_area': item.land_area,
        'area_1_type_id': item.combined.area_1_type_id,
        'area_1': item.combined.area_1,
        'area_2_type_id': item.combined.area_2_type_id,
        'area_2': item.combined.area_2,
        'area_3_type_id': item.combined.area_3_type_id,
        'area_3': item.combined.area_3,
        'area_4_type_id': item.combined.area_4_type_id,
        'area_4': item.combined.area_4
      })
    },
    update(key, value) {
      if (key === 'area_form') {
        this.$emit('input', {
          ...this.local,
          ...value
        })
      } else {
        this.$emit('input', { ...this.local, [key]: value })
      }
    },
    updateAreaProfileForm(address_detail_pid, value) {
      this.$emit('input', {
        ...this.local,
        areaProfiles: {
          ...this.local.areaProfiles,
          [address_detail_pid]: value
        }
      })
    }
  }
}
</script>
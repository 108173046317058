<template>
  <div>
    <div class="caption">{{ label }}</div>
    <div>
      <v-icon v-if="!unlocked" small>$mdi-lock</v-icon>
      <div v-else-if="value !== null" class="d-flex">
        <v-icon v-if="!hideIcon" :color="value ? 'success' : 'error'" left>{{ value ? '$mdi-check-circle-outline' : '$mdi-close-circle-outline' }}</v-icon>
        <span v-if="!hideText">{{ value ? 'Yes' : 'No' }}</span>
      </div>
      <div v-else>-</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    },
    label: {
      type: String
    },
    hideText: {
      type: Boolean,
      default: false
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    unlocked: {
      type: Boolean,
      default: true
    }
  }
}
</script>
export default {
  computed: {
    salesType() {
      return this.$route.query.sales_type
    },
    isSalesComp() {
      return this.salesType === 'salesComp'
    },
    isLeaseComp() {
      return this.salesType === 'leaseComp'
    }
  }
}
<template>
  <div v-if="pageCount > 1" class="d-flex flex-grow-1 justify-space-between align-center py-2">
    <div v-if="showOptions">{{ (value - 1) * itemsPerPage + 1 }}-{{ value * itemsPerPage > total ? total : value * itemsPerPage }} of {{ total }}</div>
    <v-pagination
      v-model="page"
      :length="pageCount"
      v-bind="$attrs"
      class="flex-grow-1"
      @input="$emit('input', $event)"
    ></v-pagination>
    <div v-if="showOptions" class="d-flex align-center justify-end">
      <span class="mr-2">Rows Per page: </span>
      <v-select
        :value="itemsPerPage"
        dense
        hide-details
        :items="[5, 10, 20]"
        disabled
        style="max-width: 78px;"
      ></v-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: 1,
      type: Number
    },
    itemsPerPage: {
      default: 10,
      required: false,
      type: Number
    },
    total: {
      default: 0,
      required: false,
      type: Number
    },
    fetchFunction: {
      type: Function
    },
    showOptions: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    pageCount() {
      return Math.ceil(this.total / this.itemsPerPage)
    },
    page: {
      get: function() {
        return +this.$route.query.page || 1
      },
      set: async function(newVal) {
        try {
          await this.fetchFunction({ page: newVal })

          this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newVal } })
        } catch {
          // do nothing
        }
      }
    }
  }
}
</script>
<template>
  <v-select
    v-bind="$attrs"
    :id="id"
    :value="value"
    :items="dropdowns.buildingTypes"
    item-value="id"
    item-text="name"
    multiple
    placeholder="Property Types"
    @change="$emit('input', $event)"
  >
    <template v-slot:selection="data">
      <v-chip
        v-if="!limited || data.index === 0"
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        small
        label
        @click="data.select"
        @click:close="remove(data.item)"
      >
        {{ data.item.name }}
      </v-chip>
      <span v-if="limited && data.index === 1">(+{{ value.length - 1 }} more)</span>
    </template>
  </v-select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['value', 'id', 'limited'],
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('dropdown', ['dropdowns'])
  },
  methods: {
    remove(item) {
      this.$emit('input', this.value.filter((v) => v !== item.id))
    }
  }
}
</script>
<template>
  <div>
    <div class="pa-2 text-center">No sales record found</div>
    <div class="pa-4">
      <div class="d-flex justify-space-between">
        <div class="subtitle-2 font-weight-bold">
          <!-- {{ item.addressRels[0].address.full_address }}  -->
        </div>
      </div>

      <v-btn
        block
        color="primary"
        :to="`/comps/create?sales_type=${salesType}`"
      >
        Add a sale record
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: ['item', 'selected', 'salesType'],

  components: {
  },

  methods: {
    ...mapActions('property', ['selectComp'])
  }
}
</script>
<template>
  <div class="">
    <v-app-bar
      color="white"
      fixed
    >
      <div class="container d-flex align-center h-100 py-0">
        <LogoComponent />
  
        <v-spacer></v-spacer>
  
        <div class="d-none d-sm-flex gap-column-md">
          <template v-for="(link, i) in links">
            <v-menu open-on-hover v-if="link.items" offset-y :key="i">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                >
                  {{ link.text }}
                </v-btn>
              </template>
              <v-list nav dense>
                <v-list-item
                  v-for="(item, index) in link.items"
                  :key="index"
                  :to="item.to"
                >
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn v-else text :to="link.to" :key="i">{{ link.text }}</v-btn>
          </template>

          <v-btn
            v-if="isAuth"
            color="primary"
            text
            href="/browse/map?sales_type=salesComp"
          >
            <v-icon left class="mt-1">$mdi-magnify</v-icon>
            Browse comps
          </v-btn>
          <template v-else>
            <v-divider vertical />
            <v-btn to="/auth/signin" text>Login</v-btn>
            <v-btn to="/auth/signup" color="primary">Sign Up</v-btn>
          </template>
        </div>
        <v-btn class="d-block d-sm-none" icon @click="drawer = true">
          <v-icon>$mdi-menu</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer absolute temporary v-model="drawer">
      <v-list>
        <template v-for="(link, i) in links">
          <v-list-group
            v-if="link.items"
            :key="i"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="link.text"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(item, j) in link.items"
              :key="j"
              :to="item.to"
              class="pl-8"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="i"
            :to="link.to"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ link.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-divider></v-divider>
      <v-list-item
        to="/auth/signin"
      >
        <v-list-item-content>
          <v-list-item-title>
            Login
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>

    <v-main class="py-4 main">
      <router-view></router-view>
    </v-main>

    <v-footer class="footer">
      <v-container class="d-flex justify-space-between px-0 px-sm-2">
        <div>Propedia 2024</div>
        <div class="d-flex gap-md">
          <router-link to="/privacy-policy" class="">Privacy Policy</router-link>
          <router-link to="/terms" class="">Terms & Conditions</router-link>
          <router-link to="/contact" class="">Contact</router-link>
        </div>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import LogoComponent from '../components/LogoComponent.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    LogoComponent
  },
  computed: {
    ...mapGetters('auth', ['isAuth'])
  },
  data() {
    return {
      drawer: false,
      links: [
        {
          text: 'Product',
          items: [
            {
              text: 'Propedia Explorer',
              to: '/propedia-explorer'
            },
            {
              text: 'Full Stack Tailored Development Service',
              to: '/fullstack-development-service'
            }
          ]
        },
        {
          text: 'About Us',
          to: '/teams'
        },
        {
          text: 'Support',
          to: '/contact'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.main {
  background-color: white;
  margin-top: 64px;
}
.footer {
  background: #222628;
  color: white;
  a {
    color: white;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .main {
    margin-top: 56px;
  }
  .footer {
    font-size: 10px;
  }
}
</style>
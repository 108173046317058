<template>
  <div>
    <v-navigation-drawer
      app
      clipped
      class="elevation-3"
      :value="adminDrawer"
    >
      <v-list
        dense
        shaped
      >
        <template
          v-for="item in items"
        >
          <v-list-group
            v-if="item.subItems"
            :key="item.key"
            :prepend-icon="item.icon"
            :value="item.subItems.map((subItem) => subItem.to).includes($route.path)"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="({ title, to }, i) in item.subItems"
              :key="i"
              :to="{ path: to }"
            >
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            :key="item.title"
            :to="{ path: item.to }"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="item.title" />
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      color="white"
      tile
      elevation="1"
      outlined
      app
      clipped-left
      clipped-right
    >
      <div class="h-100">
        <LogoComponent />
      </div>

      <v-spacer />

      <div>
        <v-btn
          color="primary"
          text
          to="/browse/map"
        >
          <v-icon left class="mt-1">$mdi-magnify</v-icon>
          Browse comps
        </v-btn>
        <UserMenu />
      </div>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <div class="flex-grow-1">
          <v-row class="my-0">
            <v-col class="py-0">
              <admin-drawer-toggler />
            </v-col>
            <v-col v-if="salesTypeRoutes.includes($route.name)" class="py-0">
              <sales-type-toggler />
            </v-col>
          </v-row>

          <router-view></router-view>
        </div>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import LogoComponent from '../components/LogoComponent.vue'
import UserMenu from '../components/UserMenu.vue'
import AdminDrawerToggler from '@/components/admin/DrawerToggler'
import SalesTypeToggler from '@/components/layout/SalesTypeToggler'

export default {
  components: {
    LogoComponent,
    UserMenu,
    AdminDrawerToggler,
    SalesTypeToggler
  },
  
  data() {
    return {
      items: [
        {
          title: 'Public Comps',
          icon: '$mdi-sale',
          key: 'admin.comps',
          subItems: [
            {
              title: 'By Address',
              to: '/admin/comps/by_address'
            }, {
              title: 'By Sales/Leases',
              to: '/admin/comps/by_sales'
            }
          ]
        }, {
          title: 'Address',
          icon: '$mdi-map-marker',
          key: 'addresses',
          subItems: [
            {
              title: 'Manual Address',
              to: '/admin/manual-addresses'
            }
          ]
        }, {
          title: 'Companies',
          to: '/admin/companies',
          icon: '$mdi-domain'
        }, {
          title: 'Users',
          to: '/admin/users',
          icon: '$mdi-account-multiple'
        }
      ],

      salesTypeRoutes: [
        'admin.comps.by_sales',
        'admin.comps.by_address'
      ]
    }
  },

  computed: {
    ...mapState('app', ['adminDrawer', 'adminRightDrawer'])
  },

  mounted() {
    this.init()
  },

  methods: {
    ...mapActions('app', ['setAdminRightDrawer']),
    ...mapActions('dropdown', ['getDropdowns']),
    ...mapActions('setting', ['getColumns', 'getUserColumns']),
    ...mapActions('zoning_and_overlay', ['getZoneCodes', 'getOverlayCodes']),
    init() {
      this.getDropdowns()
      this.getColumns().then(() => {
        this.getUserColumns()
      })
      
      this.getZoneCodes()
      this.getOverlayCodes()
    }
  }
}
</script>
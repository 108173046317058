<template>
  <v-app>
    <v-snackbar
      v-model="snackbar.value"
      :color="snackbar.color"
      text
      vertical
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          color="darkslategray"
          v-bind="attrs"
          @click="hideSnackbar(false)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Layout component -->
    <component :is="currentLayout">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </component>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'

// import config from './configs'
import defaultLayout from '@/layouts/DefaultLayout'
import adminLayout from '@/layouts/AdminLayout'
import AuthLayout from '@/layouts/AuthLayout'
import DevLayout from '@/layouts/DevLayout'
import StaticLayout from '@/layouts/StaticLayout'
import DashboardLayout from '@/layouts/DashboardLayout'

export default {
  components: {
    AuthLayout,
    adminLayout,
    defaultLayout,
    DevLayout,
    StaticLayout,
    DashboardLayout
  },
  computed: {
    ...mapState('app', ['snackbar']),
    // ...mapState({
      // isPageLoading: (state) => state.app.isPageLoading,
      // isAppReady: (state) => state.auth.isAppReady
    // }),
    // isRouterLoaded: function() {
    //   if (this.$route.name !== null) return true

    //   return false
    // },
    currentLayout: function() {
      return (this.$route.meta.layout || 'Auth') + 'Layout'
    }
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      // ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  },
  created() {
    // console.log(this.$route.meta.layout)
    // document.getElementById('loading').style.display = 'none'
  },
  methods: {
    ...mapActions('app', ['hideSnackbar'])
  }
}
</script>

<style>
#app {
  background: #e3e5e5;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

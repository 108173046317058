import Vue from 'vue'
import store from '../store'
import api from '../api'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

async function bootstrap() {
  const token = Vue.$cookies.get('token')

  if (token) {
    store.commit('auth/SET_TOKEN', token, { root: true })

    // const settingsPromise = new Promise((resolve, reject) => {
    //   api.get('/settings/columns').then((response) => {
    //     resolve(response)
    //   }).catch((err) => {
    //     reject(err)
    //   })
    // })
    const authPromise = new Promise((resolve, reject) => {
      api.get('/auth/me', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    });

    try {
      const [authRes] = await Promise.all([authPromise])

      // store.commit('auth/SET_COLUMNS', settingsRes.data, { root: true })
      store.commit('auth/SET_AUTH_DATA', authRes.data.user, { root: true })
    } catch(err) {
      Vue.$cookies.remove('token')
      // window.location = '/auth/signin'
    }
  } else {
    store.commit('auth/SET_TOKEN', null, { root: true })
    Vue.$cookies.remove('token')
  }
}

export default bootstrap
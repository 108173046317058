<template>
  <v-menu offset-y v-model="menu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        outlined
        style="border-color: grey;"
        :style="$attrs.right ? 'margin-left: 4px;' : ''"
        class="px-2"
        :class="$attrs.class"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
      >
        {{ value }}
        <v-icon small class="ml-1">{{ menu ? '$mdi-chevron-up' : '$mdi-chevron-down' }}</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item v-for="(item, i) in items" :key="i" @click="$emit('input', item)">
        <v-list-item-content>{{ item }}</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: ['items', 'value'],
  data () {
    return {
      menu: false
    }
  }
}
</script>
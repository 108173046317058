<template>
  <v-menu v-if="profiles && profiles.length" offset-y>
    <template v-slot:activator="{ on, attrs, value }">
      <div v-bind="attrs" v-on="on" class="d-flex align-center primary--text userselect-none" style="height: 20px;">
        {{ dropdownLabel }}
        <v-icon color="primary">{{ value ? '$mdi-menu-up' : '$mdi-menu-down' }}</v-icon>
      </div>
    </template>
    <v-list>
      <v-list-item-group>
        <v-list-item
          v-for="item in profiles"
          :key="item.id"
          @click="$emit('input', item)"
        >
          <v-list-item-content :class="{ 'primary--text': selectedProfile?.id === item.id }">
            <div>
              <div class="d-flex align-center caption font-weight-bold">
                <v-icon small :color="selectedProfile?.id === item.id ? 'primary' : 'black'" class="mr-1">$mdi-clock-time-two-outline</v-icon>
                <div class="d-flex align-center">
                  <span>{{ isSalesComp ? 'Sale Date:' : 'Lease Date:' }}</span>
                  <NormalField :value="isSalesComp ? item.sale_dt : item.lease_dt" class="ml-1" />
                </div>
                <span class="mx-2">|</span>
                <div class="d-flex align-center">
                  <span>{{ isSalesComp ? 'Sale Price:' : 'Passing Rent:' }}</span>
                  <NormalField type="price" :value="isSalesComp ? item.sale_price : item.passing_rent" class="ml-1" />
                </div>
              </div>

              <PropertyType :type-ids="item.type_ids" :color="selectedProfile?.id === item.id ? 'primary' : ''" class="mt-1" />

              <div class="d-flex align-center caption mt-1">
                <div v-if="item.land_area" class="d-flex align-center mr-1">
                  Land: <NormalField type="area" :value="item.land_area" class="ml-1" />
                </div>
                <div class="d-flex align-center">
                  Building: <NormalField type="area" :value="item.building_area" class="ml-1" />
                </div>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import NormalField from '@/components/attributes/NormalField'
import PropertyType from '@/components/attributes/PropertyType'

export default {
  components: {
    NormalField,
    PropertyType
  },
  props: [
    'selectedProfile',
    'profiles',
    'salesType'
  ],
  computed: {
    isSalesComp() {
      return this.salesType === 'salesComp' ? true : false
    },
    dropdownLabel() {
      if (this.selectedProfile) {
        if (this.isSalesComp) {
          return `${this.selectedProfile.sale_dt} - $${this.$options.filters.number(this.selectedProfile.sale_price)}`
        } else {
          return `${this.selectedProfile.lease_dt} - $${this.$options.filters.number(this.selectedProfile.passing_rent)}`
        }
      } else {
        return 'Choose from the previous Land and Building'
      }
    }
  },
}
</script>
<template>
  <div>
    <v-overlay :value="loading" :z-index="1" opacity="0.16">
      <Loader/>
    </v-overlay>
    <template v-if="items.length">
      <PropertyCard
        v-for="(item, i) in items"
        :key="i"
        :item="item"
        :sales-type="salesType"
      />
    </template>
    <EmptyPropertyCard v-else :sales-type="salesType" />
  </div>
</template>

<script>
import PropertyCard from './PropertyCard.vue'
import EmptyPropertyCard from './EmptyPropertyCard.vue'

export default {
  components: {
    PropertyCard,
    EmptyPropertyCard
  },

  props: ['items','loading', 'salesType'],

  data() {
    return {
      mode: 'list', // search, list,
      applying: false,
    }
  }
}
</script>
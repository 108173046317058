<template>
  <v-radio-group
    :value="local.value"
    class="mt-2"
    hide-details
    @change="update('value', $event)"
  >
    <v-radio
      v-for="(option, i) in options"
      :key="i"
      :label="option.title"
      :value="option.value"
    ></v-radio>
    <div class="d-flex">
      <v-radio
        value="custom"
      >
      </v-radio>
      <v-row dense>
        <v-col>
          <DatePickerText
            v-model="local.from_dt"
            @input="update('from_dt', $event)"
          />
        </v-col>
        <v-col>
          <DatePickerText
            v-model="local.to_dt"
            @input="update('to_dt', $event)"
          />
        </v-col>
      </v-row>
    </div>
  </v-radio-group>
</template>

<script>
import options from '@/@propedia/constants/options'

export default {
  props: ['value'],

  data() {
    return {
      options: options.filter.soldDt
    }
  },

  computed: {
    local() {
      return this.value ?? {
        value: 'all',
        from_dt: null,
        to_dt: null
      }
    }
  },

  methods: {
    update(key, value) {
      if (key === 'value' && value !== 'custom') {
        this.$emit('input', {
          value: value,
          from_dt: null,
          to_dt: null
        })
      } else {
        this.$emit('input', {
          ...this.local,
          value: 'custom',
          [key]: value
        })
      }
    }
  }
}
</script>
<template>
  <div>
    <v-navigation-drawer
      app
      clipped
      stateless
      :value="true"
      class="elevation-3"
    >
      <v-list
        dense
        shaped
      >
        <template
          v-for="item in items"
        >
          <v-list-group
            v-if="item.subItems"
            :key="item.key"
            :prepend-icon="item.icon"
            :value="$route.name.includes(item.key)"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="({ title, to }, i) in item.subItems"
              :key="i"
              :to="to"
            >
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            :key="item.title"
            :to="item.to"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="item.title" />
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      color="white"
      tile
      elevation="1"
      outlined
      app
      clipped-left
      clipped-right
    >
      <div class="h-100">
        <logo-component></logo-component>
      </div>

      <v-spacer />

      <div>
        <user-menu />
      </div>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LogoComponent from '../components/LogoComponent.vue'
import UserMenu from '../components/UserMenu.vue'

export default {
  components: {
    LogoComponent,
    UserMenu
  },
  data() {
    return {
      items: [
        {
          title: 'Server Resource',
          icon: '$mdi-cog-transfer-outline',
          to: '/dev/dashboard'
        }, {
          title: 'Dev Requests',
          icon: '$mdi-check-decagram',
          to: '/dev/requests'
        }
      ]
    }
  }
}
</script>
import api from '@/api'

export default {
  namespaced: true,

  state: {
    
  },
  mutations: {
    // SET_PUBLISHING(state, loading) { state.loadingItems = loading }
  },
  actions: {
    // Publish comp or hide comp
    publish(_, payload) {
      return api.post('/comps/publish', payload)
    }
  }
}
import Vue from 'vue'

Vue.prototype.$rules = {
  required: (value) => (value && Boolean(value)) || 'Required',
  length: (value) => (value && Array(value) && value.length > 0) || 'Choose at least one',
  emailFormat: (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
  percent: (v) => {
    const vv = parseFloat(v)

    return isNaN(vv) || vv < 0 || vv > 100 ? 'Invalid percentage value' : true
  },
  phoneFormat: (v) => /^(?:\(\d{3}\)|\d{3}-)\d{3}-\d{4}$/.test(v) || 'Phone number must be valid',
  website: (v) => v ? /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g.test(v) || 'Invalid website url' : true
}
<template>
  <v-layout>
    <v-navigation-drawer
      :value="$route.meta.navigation === 'default'"
      app
      clipped
      stateless
      floating
      hide-overlay
      class="elevation-3"
    >
      <v-list subheader dense nav>
        <template
          v-for="(item, i) in navigationItems"
        >
          <v-divider v-if="item.divider" :key="`divider-${i}`" class="my-2" />
          <v-subheader v-else-if="item.subheader" :key="`subheader-${i}`">{{ item.subheader }}</v-subheader>
          <v-list-item
            v-else
            :key="`item-${i}`"
            :to="item.to"
            link
            active-class="primary--text"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      :value="isBrowse && (viewMode === 'map' && mapMode || viewMode === 'list' && listSearchDrawer)"
      app
      clipped
      stateless
      floating
      hide-overlay
      :temporary="viewMode === 'map'"
      class="elevation-3"
      :width="isBrowse ? 360 : 280"
      :style="viewMode === 'map' ? 'top: 64px; height: calc(100vh - 64px);' : ''"
    >
      <template
        v-slot:append
        v-if="
          (viewMode === 'list' || viewMode === 'map' && mapMode === 'search') ||
          (viewMode === 'map' && mapMode === 'properties' && pageCount > 1)
        "
      >
        <v-toolbar
          dense
          elevation="3"
          style="z-index: 2;"
        >
          <div v-if="viewMode === 'list' || viewMode === 'map' && mapMode === 'search'" class="d-flex flex-grow-1 align-center">
            <v-btn text color="primary" @click="clearFilter">Clear Filter</v-btn>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary">Save Search</v-btn>
          </div>

          <ListPagination
            v-if="viewMode === 'map' && mapMode === 'properties' && pageCount > 1"
            :total-visible="7"
            :show-options="false"
            :total="total"
            :items-per-page="10"
            :fetch-function="getItems"
          />
        </v-toolbar>
      </template>
      <NavBtns v-model="mapMode" v-if="viewMode === 'map'"/>
      <SuburbAutoComplete
        v-if="viewMode === 'map'"
        v-model="addressSearchLoc"
        clearable
        solo
        background-color="white"
        hide-details
        rounded
        placeholder="Search for address or suburb"
        :show-suburb="true"
        height="48"
        style="
          position: absolute;
          top: 32px;
          min-width: 573px;
          width: 573px;
          transform: translate(75%, -50%);
          display: flex;
          flex-direction: column;
        "
        @input="handleAddressChange(addressSearchLoc)"
      />
      <template
        v-slot:prepend
        v-if="
          viewMode === 'list' ||
          (viewMode === 'map' && mapMode === 'search')
        "
      >
        <v-toolbar
          dense
          elevation="3"
          style="z-index: 2;"
        >
          <v-tabs
            v-if="viewMode === 'list' || (viewMode === 'map' && mapNavMode === 'search')"
            v-model="searchMode"
            grow
          >
            <v-tab key="search">
              Search Filter
            </v-tab>
            <v-tab key="saved-searches">
              Saved Searches
            </v-tab>
          </v-tabs>
          <v-tabs
            v-else-if="viewMode === 'map' && mapNavMode === 'reporting'"
            grow
          >
            <v-tab key="report">
              Report
            </v-tab>
            <v-tab key="manage-report">
              Manage Report
            </v-tab>
          </v-tabs>
        </v-toolbar>
      </template>
      <div v-if="isBrowse" style="height: calc(100vh - 160px); z-index: 1;">
        <ListSearchForm
          v-if="viewMode === 'list' || viewMode === 'map' && mapMode === 'search'"
          v-model="filter"
          :is-sales-comp="$route.query.sales_type === 'salesComp'"
        />
        <PropertiesList
          v-if="viewMode === 'map' && mapMode === 'properties'"
          :sales-type="$route.query.sales_type"
          :items="items"
          :get-items="getItems"
          :loading="loadingItems"
        />
        <ReportingPanel
          v-if="viewMode === 'map' && mapMode === 'reporting'"
        />
      </div>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      color="white"
      tile
      elevation="1"
      outlined
      app
      clipped-left
      clipped-right
      style="z-index: 10;"
    >
      <div
        class="d-flex flex-grow-1 justify-space-between align-center mx-auto"
      >
        <div cols="4" class="h-100 py-0 pl-0 d-flex align-center">
          <LogoComponent />
          <SalesTypeToggler v-if="salesTypeRoutes.includes($route.name)" class="ml-8"/>
        </div>

        <div cols="2" class="text-center py-0">
          <v-btn-toggle
            v-if="isBrowse"
            :value="viewMode"
            rounded
            dense
            color="primary"
          >
            <v-btn
              v-for="(btn, i) in typeList"
              :key="i"
              :value="btn.value"
              :to="{ name: `browse.${btn.value}`, query: $route.query }"
            >
              <v-icon :color="$route.name.includes(btn.value) ? 'primary' : ''">{{ btn.icon }}</v-icon>
              <span class="d-none d-md-block">{{ btn.text }}</span>
            </v-btn>
          </v-btn-toggle>
        </div>

        <div cols="6" class="d-flex align-center justify-end py-0 pr-0 gap-sm">
          <v-btn
            v-if="isBrowse"
            color="primary"
            text
            :to="{
              name: 'comps.create',
              query: {
                sales_type: $route.query.sales_type
              }
            }"
          >
            <v-icon left>$mdi-plus</v-icon>
            Add New
          </v-btn>
          <v-btn
            v-if="!isBrowse"
            color="primary"
            text
            :href="`/browse/map?sales_type=${salesType}`"
          >
            <v-icon left class="mt-1">$mdi-magnify</v-icon>
            Browse comps
          </v-btn>
          <UserMenu v-if="isAuth" />
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <router-view
        :filter="filter"
        @pagination-input="onPaginationInput"
        @drawing-updated="filter = { ...filter, drawing: $event }"
        @suburb-changed="handleAddressChange($event)"
      ></router-view>

      <v-dialog
        v-model="userShortlistModal"
        width="300"
        scrollable
        persistent
        no-click-animation
        :retain-focus="false"
      >
        <v-card width="100%">
          <v-card-title class="subtitle-2 font-weight-bold py-2 px-4">
            <span>Add to shortlist</span>
            <v-icon class="ml-auto" @click="handleShortlistModalClose">$mdi-close</v-icon>
          </v-card-title>
          <v-divider></v-divider>

          <v-alert v-if="userShortlist.length === 0" type="info" dense text class="mt-2">You don't have shortlist yet. You can add a shortlist.</v-alert>

          <v-card-text v-if="userShortlist.length" class="pb-2">
            <v-checkbox
              v-for="(v, i) in userShortlist"
              :key="i"
              v-model="shortlistVal"
              :label="v.title"
              :value="v.id"
              :ripple="false"
              dense
              hide-details
              @click="handleToggleToUserShortlist(v)"
            ></v-checkbox>
          </v-card-text>

          <v-divider></v-divider>

          <v-expand-transition>
            <div v-show="showNewUserShortlist">
              <v-card-text>
                <v-form
                  ref="userShortlistForm"
                  v-model="userShortlistFormValid"
                  lazy-validation
                >
                  <label for="shortlist-name" class="caption">New Shortlist Name</label>
                  <v-text-field
                    id="shortlist-name"
                    v-model="newShortlistTitle"
                    outlined
                    hide-details
                    :rules="[$rules.required]"
                  />
                </v-form>
              </v-card-text>
            </div>
          </v-expand-transition>

          <v-card-actions class="justify-end">
            <v-btn v-if="!showNewUserShortlist" depressed block @click="showNewUserShortlist = true"><v-icon left>$mdi-plus</v-icon>Create new shortlist</v-btn>
            <div v-else class="d-flex gap-sm">
              <v-btn text @click="showNewUserShortlist = false">Cancel</v-btn>
              <v-btn
                color="primary"
                :loading="addingUserShortlist"
                :disabled="addingUserShortlist"
                @click="handleUserShortlistCreate"
              >Create</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <CompEditModal
        v-model="compEditModal"
        :sales-type="salesType"
        :comp-uuid="compUuidForEdit"
        :show-reviewers="true"
        @updated="onCompEditCompleted"
      >
        <template #title v-if="$route.name === 'comp_reviews'">
          Review Comp
        </template>
      </CompEditModal>
    </v-main>
  </v-layout>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations
} from 'vuex'

import LogoComponent from '@/components/LogoComponent.vue'
import UserMenu from '@/components/UserMenu.vue'
import ListPagination from '@/components/ListPagination'
import SalesTypeToggler from '@/components/layout/SalesTypeToggler.vue'
import ListSearchForm from '@/components/forms/ListSearchForm'
import ReportingPanel from '@/components/panels/ReportingPanel'
import NavBtns from '@/components/map-navications/NavButtons.vue'
import PropertiesList from '../components/PropertiesList.vue'
import SuburbAutoComplete from '@/components/SuburbAutoComplete'
import CompEditModal from '@/components/modals/CompEdit'

import { removeEmpty } from '@/helper/utils'

let abortController

export default {
  components: {
    LogoComponent,
    UserMenu,
    ListPagination,
    SalesTypeToggler,
    ListSearchForm,
    NavBtns,
    PropertiesList,
    SuburbAutoComplete,
    ReportingPanel,
    CompEditModal
  },

  data() {
    return {
      searchMode: 'search',
      drawing: null,

      navigationItems: [
        {
          to: '/dashboard',
          icon: '$mdi-home-city',
          title: 'Dashboard'
        }, {
          subheader: 'Valuation'
        }, {
          to: '/jobs',
          icon: '$mdi-laptop',
          title: 'Jobs'
        }, {
          to: '/reports',
          icon: '$mdi-chart-bar',
          title: 'Reports'
        }, {
          subheader: 'Comps'
        }, {
          to: '/sales',
          icon: '$mdi-sale',
          title: 'My Comps'
        }, {
          to: '/comp_reviews',
          icon: '$mdi-domain',
          title: 'Reviews'
        }, {
          subheader: 'Management'
        }, {
          to: '/users',
          icon: '$mdi-account-multiple',
          title: 'Users'
        }, {
          to: '/addresses',
          icon: '$mdi-map-marker',
          title: 'Manual Addresses'
        }, {
          subheader: 'Account'
        }, {
          to: '/account',
          icon: '$mdi-card-account-mail',
          title: 'Account'
        }, {
          to: '/account_settings',
          icon: '$mdi-lock',
          title: 'Account Settings'
        }, {
          subheader: 'System Monitor'
        }, {
          to: '/activities_logs',
          icon: '$mdi-history',
          title: 'Activities & Logs'
        }, {
          to: '/files_backups',
          icon: '$mdi-file-table',
          title: 'Files & Backup'
        }, {
          divider: true
        }, {
          to: '/settings',
          icon: '$mdi-cog',
          title: 'Settings'
        }, {
          to: '/help',
          icon: '$mdi-help-circle-outline',
          title: 'Help'
        }, {
          to: '/send_feedback',
          icon: '$mdi-comment-quote',
          title: 'Send Feedback'
        }
      ],

      salesTypeRoutes: [
        'browse.list',
        'browse.map'
      ],
      viewModeBtnRoutes: [
        'browse.map',
        'browse.list',
        'vertical.create',
        'me.comps',
        'me.submissions',
        'me.reporting',
        'reporting.create'
      ],
      btnList: [
        {
          type: 'comps',
          text: 'Sales',
          disabled: false
        }, {
          type: 'lease_comps',
          text: 'Leases',
          disabled: false
        }
      ],
      typeList: [
        {
          icon: '$mdi-view-list',
          value: 'list',
          text: 'List View'
        }, {
          icon: '$mdi-map',
          value: 'map',
          text: 'Map View'
        }
      ],
      showNewUserShortlist: false,
      newShortlistTitle: null,
      addingUserShortlist: false,
      userShortlistFormValid: false,
      shortlistVal: []
    }
  },

  computed: {
    ...mapState('auth', ['user']),
    ...mapState('app', ['listSearchDrawer']),
    ...mapState('address', [
      'addressSearch',
      'fullAddressCandidates',
      'suburbCandidates'
    ]),
    ...mapState('property', [
      'loadingItems',
      'total',
      'items',
      'compDetail',

      'mapNavMode',

      'userShortlistModal',
      'compIdForShortlist',
      'userShortlist',
      'sledUserShortlistId',
      
      'compEditModal',
      'compUuidForEdit'
    ]),
    ...mapGetters('property', ['pageCount']),
    ...mapGetters('address', ['candidates']),
    ...mapGetters('auth', [
      'isAuth',
      'userRole',
      'isCompany'
    ]),
    viewMode() {
      return this.$route.meta.navigation
    },
    isBrowse() {
      return this.viewMode === 'list' || this.viewMode === 'map'
    },
    isCreate() {
      return this.$route.name === 'comps.create'
    },
    navigationWidth() {
      return this.isBrowse ? 360 : undefined
    },
    showDrawer() {
      return this.viewMode && this.mapNavMode
      // return this.menuItems.length
    },
    topItems() {
      return []
    },
    leftItems() {
      return []
    },
    topMenuItems() {
      return this.topItems.filter((v) => {
        if (Array.isArray(v.for)) {
          return v.for.includes(this.userRole)
        } else {
          v.for === this.userRole
        }
      })
    },
    salesType() {
      return this.$route.query.sales_type
    },
    mapMode: {
      get: function() {
        return this.mapNavMode
      },
      set: function(m) {
        this.setMapNavMode(m)
      }
    },
    addressSearchLoc: {
      get() {
        return this.addressSearch
      },
      set(v) {
        return this.SET_ADDRESS_SEARCH(v)
      }
    },
    filter: {
      get: function() {
        let typeIds = this.$route.query.type_ids
        let zoneCodes = this.$route.query.zone_codes
        let overlayCodes = this.$route.query.overlay_codes

        const priceRange = {
          value: 'all',
          from_price: null,
          to_price: null
        }
        const dateRange = {
          value: 'all',
          from_dt: null,
          to_dt: null
        }
        const buildingSizeRange = {
          from: null,
          to: null
        }
        const landSizeRange = {
          from: null,
          to: null
        }

        if (!!typeIds && typeof typeIds === 'string') typeIds = [typeIds];
        if (!!zoneCodes && typeof zoneCodes === 'string') zoneCodes = [zoneCodes];
        if (!!overlayCodes && typeof overlayCodes === 'string') overlayCodes = [overlayCodes];

        // Price range filter
        if (this.$route.query.price_range_from) {
          priceRange.value = 'custom'
          priceRange.from_price = this.$route.query.price_range_from
        }
        if (this.$route.query.price_range_to) {
          priceRange.value = 'custom'
          priceRange.to_price = this.$route.query.price_range_to
        }
        if (this.$route.query.price_range) {
          priceRange.value = this.$route.query.price_range
        }

        // Date range filter
        if (this.$route.query.date_range_from) {
          dateRange.value = 'custom'
          dateRange.from_dt = this.$route.query.date_range_from
        }
        if (this.$route.query.date_range_to) {
          dateRange.value = 'custom'
          dateRange.to_dt = this.$route.query.date_range_to
        }
        if (this.$route.query.date_range) {
          dateRange.value = this.$route.query.date_range
        }

        // Building size range filter
        if (this.$route.query.building_size_from) {
          buildingSizeRange.from = this.$route.query.building_size_from
        }
        if (this.$route.query.building_size_to) {
          buildingSizeRange.to = this.$route.query.building_size_to
        }

        // Land size range filter
        if (this.$route.query.land_size_from) {
          landSizeRange.from = this.$route.query.land_size_from
        }
        if (this.$route.query.land_size_to) {
          landSizeRange.to = this.$route.query.land_size_to
        }

        return {
          drawing: this.$route.query.drawing,
          typeIds: typeIds ? typeIds.map((id) => +id) : [],
          dateRange,
          priceRange,
          buildingSizeRange,
          landSizeRange,
          zoneCodes: zoneCodes ?? [],
          overlayCodes: overlayCodes ?? [],
          full_address: this.$route.query.full_address,
          address_detail_pid: this.$route.query.address_detail_pid,
          suburb: this.$route.query.suburb
        }
      },
      set: async function(newVal) {
        let price_range = null, price_range_from = null, price_range_to = null
        let date_range = null, date_range_from = null, date_range_to = null

        if (newVal.priceRange) {
          price_range = newVal.priceRange.value

          if (newVal.priceRange.from_price || newVal.priceRange.to_price) {
            price_range = 'custom'
          }

          price_range_from = newVal.priceRange.from_price
          price_range_to = newVal.priceRange.to_price
        }

        if (newVal.dateRange) {
          date_range = newVal.dateRange.value

          if (newVal.dateRange.from_dt || newVal.dateRange.to_dt) {
            date_range = 'custom'
          }

          date_range_from = newVal.dateRange.from_dt
          date_range_to = newVal.dateRange.to_dt
        }

        this.drawing = newVal.drawing

        try {
          const query = {
            ...this.$route.query,
            drawing: newVal.drawing,
            type_ids: newVal.typeIds?.length ? newVal.typeIds : null,

            price_range,
            price_range_from,
            price_range_to,

            date_range,
            date_range_from,
            date_range_to,

            building_size_from: newVal.buildingSizeRange?.from,
            building_size_to: newVal.buildingSizeRange?.to,
            land_size_from: newVal.landSizeRange?.from,
            land_size_to: newVal.landSizeRange?.to,
            page: newVal.page,
            zone_codes: newVal.zoneCodes ?? null,
            overlay_codes: newVal.overlayCodes ?? null,
            address_detail_pid: newVal.address_detail_pid,
            suburb: newVal.suburb
          }

          await this.getItems(newVal)

          this.$router.push({
            ...this.$route,
            query: removeEmpty(query)
          })
        } catch {
          // do nothing
        }
      }
    }
  },

  watch: {
    viewMode() {
      if (this.isBrowse) {
        this.getItems()
      }
    },
    salesType() {
      if (this.isBrowse) {
        this.getItems()
      }
    },
    userShortlistModal(v) {
      if (v) {
        this.initializeChecks()
      }
    }
  },

  mounted() {
    this.init()
    if (this.isBrowse) {
      this.getItems()
      this.getUserShortlist(this.salesType)
      this.initAddressSearch()
    }
  },

  methods: {
    ...mapActions('setting', ['getColumns', 'getUserColumns']),
    ...mapActions('auth', ['logout']),
    ...mapActions('zoning_and_overlay', [
      'getCodes'
    ]),
    ...mapActions('property', [
      'getComps', 'getCompDetails',
      'setMapNavMode',
      'getUserShortlist', 'toggleToUserShortlist', 'addUserShortlist', 'getShortlistedComps'
    ]),
    ...mapActions('address', ['mockSuburbCandidates']),
    ...mapActions('dropdown', ['getDropdowns']),
    ...mapMutations('address', ['SET_ADDRESS_SEARCH']),
    ...mapMutations('property', [
      'SET_EDIT_MODAL', 'SET_COMPUUID_FOR_EDIT',
      'SET_USER_SHORTLIST_MODAL', 'SET_COMPID_FOR_SHORTLIST'
    ]),
    init() {
      this.getDropdowns()
      this.getColumns().then(() => {
        this.getUserColumns()
      })

      this.getCodes()
    },
    async initAddressSearch() {
      const { address_detail_pid, suburb } = this.$route.query

      if (address_detail_pid || suburb) {
        await this.mockSuburbCandidates({
          address_detail_pid: address_detail_pid,
          suburb: suburb
        })

        if (address_detail_pid) {
          this.addressSearchLoc = {
            value: this.fullAddressCandidates[0].address_detail_pid,
            type: 'full_address',
            text: this.fullAddressCandidates[0].full_address
          }
        } else {
          this.addressSearchLoc = {
            value: this.suburbCandidates[0].value,
            type: 'suburb',
            text: this.suburbCandidates[0].display
          }
        }
      }
    },
    async getItems(newVal = null) {
      if ([
        'browse.list',
        'browse.map'
      ].includes(this.$route.name) === false) {
        return false
      }

      if (abortController) {
        abortController.abort()
      }

      setTimeout(() => {
        abortController = new AbortController()

        // this.getComps({
        //   typeIds: this.filter.typeIds,
        //   zoneCodes: newVal?.zoneCodes?.length ? newVal.zoneCodes : this.filter.zoneCodes,
        //   overlayCodes: newVal?.overlayCodes?.length ? newVal.overlayCodes : this.filter.overlayCodes,
        //   salesType: this.salesType,
        //   dateRange: newVal?.dateRange || this.filter.dateRange,
        //   priceRange: newVal?.priceRange || this.filter.priceRange,
        //   buildingSizeRange: newVal?.buildingSizeRange || this.filter.buildingSizeRange,
        //   landSizeRange: newVal?.landSizeRange || this.filter.landSizeRange,
        //   page: newVal?.page || this.$route.query.page || 1,
        //   itemsPerPage: this.itemsPerPage,
        //   address_detail_pid: newVal?.address_detail_pid || this.filter.address_detail_pid,
        //   suburb: newVal?.suburb || this.filter.suburb,
        //   // drawing: newVal?.drawing || this.filter.drawing,
        //   signal: abortController.signal
        // })
        this.getComps({
          salesType: this.salesType,
          page: newVal?.page || this.$route.query.page || 1,
          itemsPerPage: this.itemsPerPage,
          filterQuery: this.$route.query,
          signal: abortController.signal
        })
      }, 50)
    },
    onPaginationInput($event) {
      this.filter = {
        ...this.filter,
        page: $event
      }
    },

    // Shortlist
    handleShortlistModalClose() {
      this.SET_COMPID_FOR_SHORTLIST(null)
      this.SET_USER_SHORTLIST_MODAL(false)
    },
    async handleToggleToUserShortlist(userShortlist) {
      await this.toggleToUserShortlist({
        compId: this.compIdForShortlist,
        userShortlistId: userShortlist.id,
        isAdd: !userShortlist.compShortlist.some((v) => v.comp_uuid == this.compIdForShortlist)
      })

      this.initializeChecks()

      if (userShortlist.id === this.sledUserShortlistId) {
        await this.getShortlistedComps({
          salesType: this.salesType,
          userShortlistId: userShortlist.id
        })
      }
    },
    async handleUserShortlistCreate() {
      if (this.$refs.userShortlistForm.validate()) {
        try {
          this.addingUserShortlist = true
          await this.addUserShortlist({
            title: this.newShortlistTitle,
            salesType: this.salesType
          })
          this.initializeChecks()
          this.showNewUserShortlist = false
          this.newShortlistTitle = null
        } finally {
          this.addingUserShortlist = false
        }
      }
    },
    async initializeChecks() {
      await this.getUserShortlist(this.salesType)

      this.shortlistVal = []
      this.userShortlist.forEach((v) => {
        if (v.compShortlist.some((vv) => vv.comp_uuid == this.compIdForShortlist)) {
          this.shortlistVal.push(v.id)
        }
      })
    },
    onCompEditCompleted() {
      if (this.compDetail) {
        this.getCompDetails({
          compUuid: this.compDetail.uuid,
          salesType: this.salesType
        })
      }

      this.getItems()

      this.SET_EDIT_MODAL(false)
      this.SET_COMPUUID_FOR_EDIT(null)
    },
    handleAddressChange(v) {
      if (!v) {
        this.filter = { ...this.filter, address_detail_pid: null, suburb: null }
      } else if (v.type === 'full_address') {
        this.filter = { ...this.filter, address_detail_pid: v.value, suburb: null }
      } else if (v.type === 'suburb') {
        this.filter = { ...this.filter, address_detail_pid: null, suburb: v.value }
      }
    },
    clearFilter() {
      this.filter = {}
      this.addressSearchLoc = null
    }
  }
}
</script>

<style scoped>
.v-navigation-drawer--mini-variant, .v-navigation-drawer {
  overflow: visible !important;
}
.v-navigation-drawer--close {
  visibility: visible;
}
</style>
import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import './plugins/rules'
import './plugins/cookies'
import './plugins/filter'
import './plugins/icons'

import './scss/index.scss'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import { VueMaskDirective } from 'v-mask'
import PerfectScrollbar from 'vue2-perfect-scrollbar'

import Loader from './components/Loader.vue'
import CurrencyText from '@/components/inputs/CurrencyText.vue'
import TenureTypeInput from '@/components/inputs/TenureTypeInput.vue'
import BtnDropdown from '@/components/inputs/BtnDropdown.vue'
import DatePickerText from '@/components/inputs/DatePickerText.vue'
import DropdownSelect from '@/components/inputs/DropdownSelect.vue'

import NormalField from '@/components/attributes/NormalField.vue'
import YesNoField from '@/components/attributes/YesNoField'
import PropertyTypeField from '@/components/attributes/PropertyType'
import ZoneOverlayField from '@/components/attributes/ZoneOverlay'
import MultiField from '@/components/attributes/MultiField'

Vue.component('Loader', Loader)
Vue.component('CurrencyText', CurrencyText)
Vue.component('TenureTypeInput', TenureTypeInput)
Vue.component('BtnDropdown', BtnDropdown)
Vue.component('DatePickerText', DatePickerText)
Vue.component('DropdownSelect', DropdownSelect)

Vue.component('NormalField', NormalField)
Vue.component('YesNoField', YesNoField)
Vue.component('PropertyTypeField', PropertyTypeField)
Vue.component('ZoneOverlayField', ZoneOverlayField)
Vue.component('MultiField', MultiField)

Vue.use(PerfectScrollbar)
Vue.use(require('vue-pusher'), {
  api_key: 'afc5e4ecf2afafeb764d',
  options: {
    cluster: 'us3'
  }
})

Vue.directive('mask', VueMaskDirective)
Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

module.exports = {
  defaultAreaForm: {
    land_area: null,
    building_area: null,
    building_area_type: 'NLA',
    area_1_type_id: null,
    area_1: null,
    area_2_type_id: null,
    area_2: null,
    area_3_type_id: null,
    area_3: null,
    area_4_type_id: null,
    area_4: null
  },
  defaultSalesForm: {
    is_multi_sale: false,
    is_offmarket: false,
    sale_price: null,
    sale_dt: null,
    sales_terms: null,
    gst_included: false,
    payment_detail_id: 1,
    terms: [
      [null, null],
      [null, null],
      [null, null],
      [null, null],
      [null, null],
      [null, null]
    ],
    tenure_type_id: 1,
    passing_rent: null,
    passing_rent_type: 'Net',
    is_passing_rent_approx: true
  },
  defaultLeaseForm: {
    commencing_rent: null,
    commencing_rent_type: 'Net',
    is_commencing_rent_approx: true,
    is_lease_dt_approx: true,
    lease_dt: null,
    lease_terms: null,
    lease_term_type: 'Years',
    is_lease_terms_approx: true,
    outgoings: null,
    is_outgoings_approx: true,
    irrecoverable_outgoings: null,
    is_irrecoverable_outgoings_approx: true,
    lease_option: null,
    lease_review: null,
    rent_incentives: null,
    tenant: null
  },
  defaultLandBuildingForm: {
    type_ids: null,
    tag_ids: null,
    with_development_potential: false,
    with_permit_approved: false,
    is_strata_unit: false
  },
  defaultFilesForm: {
    images: [],
    pdfs: []
  },
  defaultSrcForm: {
    src_of_info_id: null,
    src_url: null,
    sr_imgs: [],
    reviewer_ids: []
  },
  defaultAddressForm: {
    lot_number_prefix: null,
    lot_number: null,
    lot_number_suffix: null,

    flat_type: null,
    flat_number_prefix: null,
    flat_number: null,
    flat_number_suffix: null,
    level_type: null,
    level_number_prefix: null,
    level_number: null,
    level_number_suffix: null,

    number_first_prefix: null,
    number_first: null,
    number_first_suffix: null,
    number_last_prefix: null,
    number_last: null,
    number_last_suffix: null,
    street_name: null,
    street_type_code: null,
    street_suffix_code: null,
    postcode: null,
    state_abbreviation: null,
    locality_name: null,

    latitude: null,
    longitude: null,
    gnaf_property_pid: null
  }
}
<template>
  <div>
    <div v-if="label" class="font-weight-bold caption">{{ label }}</div>
    <div v-if="typeIds" class="d-flex flex-wrap gap-xs">
      <v-chip
        v-for="(type) in typeIds"
        :key="type"
        small
        label
        :color="color"
      >
        {{ dropdownItemName(type, 'buildingTypes') }}
      </v-chip>
    </div>
    <v-icon v-else small>$mdi-lock</v-icon>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    unlocked: {
      type: Boolean,
      default: true
    },
    typeIds: {
      type: Array,
      default: () => []
    },
    label: {
      required: false
    },
    color: {
      type: String
    }
  },
  computed: {
    ...mapGetters('dropdown', ['dropdownItemName'])
  }
}
</script>
<template>
  <v-form ref="form" v-model="isFormValid" @input="$emit('validity-changed', $event)">
    <div class="mt-2">
      <label for="src_of_info_id" class="caption required">Source of info<span>*</span></label>
      <DropdownSelect
        id="src_of_info_id"
        v-model="local.src_of_info_id"
        ky="sourceOfInfos"
        outlined
        dense
        hide-details
        :rules="[$rules.required]"
      />
    </div>
    <div class="mt-2">
      <label for="src_url" class="caption">Source URL (optional)</label>
      <v-text-field
        id="src_url"
        v-model="local.src_url"
        outlined
        dense
        hide-details
        :rules="[$rules.website]"
        @keyup.enter="submit"
      ></v-text-field>
    </div>
    <div v-if="showReviewers" class="mt-2">
      <label for="reviewer_ids" class="caption">Add reviewers (optional)</label>
      <v-autocomplete
        id="reviewer_ids"  
        v-model="local.reviewer_ids"
        :items="reviewers"
        outlined
        dense
        hide-details
        chips
        item-text="username"
        item-value="id"
        multiple
        prepend-inner-icon="$mdi-account-plus"
        @keyup.enter="submit"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="
              const index = local.reviewer_ids.indexOf(data.item.id);
              if (index >= 0) local.reviewer_ids.splice(index, 1);
            "
          >
            <v-avatar
              left
              color="primary"
            >
              <span class="white--text">{{ getInitials(data.item.username) }}</span>
            </v-avatar>
            {{ data.item.username }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <v-list-item-avatar>
            <v-avatar
              color="primary"
            >
              <span class="white--text">{{ getInitials(data.item.username) }}</span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.username"></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </div>

    <div class="mt-4">
      <v-btn
        class="primary"
        :loading="loading"
        @click="$emit('submit')"
      >Submit</v-btn>
      <v-btn
        class="ml-1"
        text
        @click="$emit('close')"
      >Cancel</v-btn>
    </div>
  </v-form>
</template>

<script>
import api from '@/api'
import { getInitials } from '@/helper/utils'

export default {
  props: ['value', 'loading', 'showReviewers'],

  data() {
    return {
      getInitials,

      isFormValid: true,
      reviewers: []
    }
  },

  computed: {
    local() {
      return this.value
    }
  },

  mounted() {
    this.getReviewers()
  },

  methods: {
    getReviewers() {
      this.loadingReviewers = true

      api.get('/users/reviewers').then((res) => {
        this.reviewers = res.data.users
      }).finally(() => {
        this.loadingReviewers = false
      })
    }
  }
}
</script>
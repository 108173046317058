<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    :items="addressCandidates"
    :search-input.sync="keyword"
    item-text="text"
    item-value="value"
    return-object
    no-filter
    no-data-text="Type address here"
    append-icon=""
    :loading="loading"
    :menu-props="{
      nudgeBottom: 2,
      maxHeight: 500
    }"
    @update:search-input="search"
    @input="handleInputChange"
  >
    <template #prepend-inner>
      <v-icon>$mdi-magnify</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Object,
      default: () => null
    },
    showFullAddress: {
      type: Boolean,
      default: true
    },
    showSuburb: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      keyword: '',
      timerId: null
    }
  },

  computed: {
    ...mapState('address', [
      'fullAddressCandidates',
      'suburbCandidates'
    ]),
    ...mapGetters('address', [
      'candidates'
    ]),
    addressCandidates() {
      return this.candidates(this.showFullAddress, this.showSuburb)
    }
  },

  methods: {
    ...mapActions('address', [
      'searchCandidates'
    ]),
    async search() {
      clearTimeout(this.timerId);

      if (this.keyword) {
        this.timerId = setTimeout(async () => {
          try {
            this.loading = true;

            await this.searchCandidates({
              keyword: this.keyword,
              showFullAddress: this.showFullAddress,
              showSuburb: this.showSuburb
            })
          } finally {
            this.loading = false
          }
        }, 500);
      }
    },
    async handleInputChange(v) {
      await this.$nextTick()

      this.$emit('input', v)
    }
  }
}
</script>
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {
  VBtn, VTab, VInput, VCheckbox, VSelect, VTooltip, VRadio
} from 'vuetify/lib';
import * as Icons from './icons'

Vue.use(Vuetify);

const icons = {}

for (const key in Icons) {
  // Create values for icons => { 'mdi-cog': mdiCog }
  icons[key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()] = Icons[key]
}

VRadio.options.props.ripple.default = false
VCheckbox.options.props.ripple.default = false
VBtn.options.props.ripple.default = false
VTab.options.props.ripple.default = false

VInput.options.props.dense.default = true
VTooltip.options.props.transition.default = 'none'
VSelect.options.props.menuProps.default = () => ({ offsetY: true })

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values: icons
  },
  theme: {
    themes: {
      light: {
        primary: '#0573E6', // blue
        secondary: '#FD766A', // salmon red
        accent: '#8DD5F6', // light blue
        green: '#3BBEBD', // green
        error: '#b71c1c'
      },
    },
  },
});
